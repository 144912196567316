import React from 'react'
import { UncontrolledTooltip } from 'reactstrap'
export const sanitizeId = (toSanitize: string): string => {
  if (!toSanitize || typeof toSanitize !== 'string') return ''

  return toSanitize
    .trim() // Remove leading/trailing spaces
    .replace(/[^\w-]/g, '_') // Replace invalid characters (anything not a-z, A-Z, 0-9, _, -) with "-"
    .replace(/-+/g, '_') // Collapse multiple dashes into a single dash
    .replace(/^[-_]+|[-_]+$/g, '') // Trim dashes/underscores from start & end
}

export const emailRenderer = (email: string, userId: string): JSX.Element => {
  const label = email.length > 25 ? `${email.slice(0, 25)}...` : email
  const cleansedId = sanitizeId(`${userId}-email`)
  if (email.length > 25) {
    return (
      <>
        <div className="font-smaller" id={cleansedId}>
          {label}
        </div>
        <UncontrolledTooltip target={cleansedId} placement="bottom">
          <div className="px-35 py-25 font-weight-bold">{email}</div>
        </UncontrolledTooltip>
      </>
    )
  }
  return (
    <div className="font-smaller" id={`${email}`}>
      {label}
    </div>
  )
}

export const nameRenderer = (name: string, userId: string): JSX.Element => {
  const label = name.length > 24 ? `${name.slice(0, 24)}...` : name
  const cleansedId = sanitizeId(`${userId}-name`)

  if (name.length > 25) {
    return (
      <>
        <div className="font-weight-bold" id={cleansedId}>
          {label}
        </div>
        <UncontrolledTooltip target={cleansedId} placement="bottom">
          <div className="px-35 py-25 font-weight-bold">{name}</div>
        </UncontrolledTooltip>
      </>
    )
  }
  return <div className="font-weight-bold">{name}</div>
}
