import React, { useMemo } from 'react'
import {Col,Row,Card,CardBody,DropdownMenu,DropdownItem,Input,FormGroup,Label} from 'reactstrap'
import { format } from 'date-fns'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck,faTrash,faArrowRotateBackward} from '@fortawesome/pro-solid-svg-icons'
import { Project } from '../../../types'
import { getPacksOrPairs } from '../../../helpers/project-type-helpers'
import { NameAndMethod } from '../../name-and-method'
import { ActionsDropDown } from '../../project-view/actions-dropdown'
export const ProjectCompletedRow: React.FC<Project &
  React.HTMLAttributes<HTMLDivElement> & { operation: string } & {
    selectedProjects: Project[]
  } & { addOrRemoveProject: (project: Project, type: string) => void } & {
    toggleConfirmationModal: () => void
  }> = ({
  className,
  selectedProjects,
  addOrRemoveProject,
  toggleConfirmationModal,
  operation,
  ...project
}): JSX.Element => {
  const formattedCompletedDate = useMemo(() => {
    return project.completedDate
      ? format(project.completedDate, 'MMM dd, yyyy')
      : '-'
  }, [project])
  const deletedDate =
    Array.isArray(project.deletedLogs) && project.deletedLogs.length > 0
      ? project.deletedLogs[project.deletedLogs.length - 1].createdAt
      : new Date()
  const formattedDeletedDate = useMemo(() => {
    return deletedDate ? format(deletedDate, 'MMM dd, yyyy, hh:mm a') : '-'
  }, [project])
  return (
    <Card
      className={`${className} project-row`}
      data-testid={
        project.isDeleted === false ? 'project-completed' : 'project-delete'
      }
    >
      <CardBody className="px-42 pt-42 pb-4">
        <Row className="mb-42">
          <Col xs="auto">
            <FormGroup check>
              <Label check className="mb-3 checkbox">
                <Input
                  type="checkbox"
                  data-testid="checkbox-completed"
                  key={project.id}
                  onClick={e => {
                    e.preventDefault()
                  }}
                  checked={
                    typeof selectedProjects != 'undefined'
                      ? selectedProjects.some(
                          (proj: Project) => proj.id === project.id
                        )
                      : false
                  }
                  onChange={e => addOrRemoveProject(project, 'checkbox')}
                />
              </Label>
            </FormGroup>
          </Col>
          <Col>
            <NameAndMethod {...project} />
          </Col>
          {project.isDeleted === true && (
            <Col xs="auto" className="text-right text-dark">
              <FontAwesomeIcon icon={faTrash} className="mr-2 text-primary" />
              <span className="font-weight-bold text-dark">
                {`Deleted on ${formattedDeletedDate}`}
              </span>
            </Col>
          )}
          {project.completedDate && project.isDeleted === false && (
            <Col xs="auto" className="text-right text-dark">
              <FontAwesomeIcon icon={faCheck} className="mr-2 text-primary" />
              <span className="font-weight-bold text-dark">
                {`Completion date is ${formattedCompletedDate}`}
              </span>
            </Col>
          )}
          <Col xs="auto" className="text-right ml-lg-45">
            <ActionsDropDown>
              <DropdownMenu right className="mt-3">
                <DropdownItem
                  data-testid={
                    operation?.toLowerCase() === 'delete'
                      ? 'confirmation-modal-completed'
                      : 'confirmation-modal-delete'
                  }
                  className="py-3 text-left font-weight-bold "
                  onClick={e => {
                    e.preventDefault()
                    addOrRemoveProject(project, 'button')
                  }}
                >
                  <FontAwesomeIcon
                    icon={
                      operation !== 'Delete' ? faArrowRotateBackward : faTrash
                    }
                    className="mr-3"
                    fixedWidth
                  />
                  {`${operation} project`}
                </DropdownItem>
              </DropdownMenu>
            </ActionsDropDown>
          </Col>
        </Row>
        <Row>
          <Col>
            <span className="h4 mb-0 text-dark font-weight-bold">
              {project.completedTasks}
              {' / '}
              {project.totalTasks}
            </span>
            <span className="text-dark font-weight-bold">
              {` ${getPacksOrPairs(project.packSize)} completed`}
            </span>
          </Col>
        </Row>
      </CardBody>
    </Card>
  )
}
