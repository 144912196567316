import React, { FC } from 'react'
import ReactDOM from 'react-dom'
import { jsonDateParser } from 'json-date-parser'
import axios from 'axios'
import './index.css'
import './App.css'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import 'abortcontroller-polyfill/dist/abortcontroller-polyfill-only'
import 'intersection-observer'
import { pdfjs } from 'react-pdf'
import { Auth0Provider, useAuth0 } from './Auth/auth'
import './custom.scss'
import { RestrictedRoute } from './components/restricted-route'
import { NavBar } from './components/navbar'
import 'ie-array-find-polyfill'
import { HomePage } from './pages/home'
import { Footer } from './components/footer'
import { NewProject } from './components/project-new'
import { ProjectView } from './components/project-view/project-view'
import { AppState } from './types'
import { TaskView } from './components/task-view'
import { useConfig, ConfigProvider } from './use-remote-config'
import { Loading } from './components/loading'
import './fonts/source-sans-pro-v12-latin-regular.eot'
import './fonts/source-sans-pro-v12-latin-regular.ttf'
import './fonts/source-sans-pro-v12-latin-regular.woff'
import './fonts/source-sans-pro-v12-latin-regular.woff2'
import './fonts/source-sans-pro-v12-latin-regular.svg'
import './fonts/source-sans-pro-v12-latin-700.eot'
import './fonts/source-sans-pro-v12-latin-700.ttf'
import './fonts/source-sans-pro-v12-latin-700.woff'
import './fonts/source-sans-pro-v12-latin-700.woff2'
import './fonts/source-sans-pro-v12-latin-700.svg'
import { NotFound } from './components/not-found'
import { MyProjects } from './components/my-projects'
import { MyTasks } from './components/my-tasks'
import { ListingPage } from './components/listing-page'

axios.defaults.transformResponse = [
  function transformResponse(data) {
    /* eslint no-param-reassign:0 */
    if (typeof data === 'string') {
      try {
        data = JSON.parse(data, jsonDateParser)
      } catch (error) {
        /* Ignore */
      }
    }
    return data
  }
]
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

// Source: https://github.com/jserz/js_piece/blob/master/DOM/ParentNode/append()/append().md
;(function(arr) {
  arr.forEach(function(item) {
    if (item.hasOwnProperty('append')) {
      return
    }
    Object.defineProperty(item, 'append', {
      configurable: true,
      enumerable: true,
      writable: true,
      value: function append() {
        const argArr = Array.prototype.slice.call(arguments)
        const docFrag = document.createDocumentFragment()

        argArr.forEach(function(argItem) {
          const isNode = argItem instanceof Node
          docFrag.append(
            isNode ? argItem : document.createTextNode(String(argItem))
          )
        })

        this.append(docFrag)
      }
    })
  })
})([Element.prototype, Document.prototype, DocumentFragment.prototype])

const onRedirectCallback = (appState: AppState): void => {
  window.history.replaceState(
    {},
    document.title,
    appState && appState.targetUrl
      ? appState.targetUrl
      : window.location.pathname
  )
}
const AppWithConfig: FC = ({ children }): JSX.Element => {
  const config = useConfig()
  if (config.loading) {
    return <Loading className="mt-5 d-block mx-auto" />
  }
  return (
    <Auth0Provider
      onRedirectCallback={onRedirectCallback}
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      {...config.config.auth0Config!}
    >
      {children}
    </Auth0Provider>
  )
}

const RoutesWithHeader: FC = (): JSX.Element => {
  return (
    <>
      <NavBar />
      <Switch>
        <Route exact path="/" component={HomePage} />
        <Route exact path="/projects" component={MyProjects} />
        <Route exact path="/tasks" component={MyTasks} />
        <Route exact path="/listing/:id/:judgeId?" component={ListingPage} />
        <RestrictedRoute
          path="/createProject/:id"
          component={NewProject}
          permission="project:create"
        />
        <RestrictedRoute
          path="/projects/:id/:tab(artefacts|judges|plan)?/:category?"
          component={ProjectView}
          permission="project:view"
        />
        <Route component={NotFound} />
      </Switch>
    </>
  )
}

const App: FC = (): JSX.Element => {
  const { loading } = useAuth0()
  if (loading) {
    return <Loading className="mt-5 d-block mx-auto" />
  }

  return (
    <Router>
      <>
        <Switch>
          <RestrictedRoute
            exact
            path="/projects/:id/tasks"
            component={TaskView}
            permission="task:list"
          />
          <Route component={RoutesWithHeader} />
        </Switch>

        <Footer />
      </>
    </Router>
  )
}

const rootElement = document.querySelector('#root')
ReactDOM.render(
  <ConfigProvider>
    <AppWithConfig>
      <App />
    </AppWithConfig>
  </ConfigProvider>,
  rootElement
)
