import React, { useMemo, useState, useEffect } from 'react'
import useReactRouter from 'use-react-router'
import axios, { AxiosResponse } from 'axios'
import { useAsyncTaskAxios, useAsyncRun } from 'react-hooks-async'
import { Table, Row, Col, Container, Button } from 'reactstrap'
import { useGetBearerToken } from './use-get-bearer-token'
import { useConfig } from '../use-remote-config'
import { format } from 'date-fns'
import {
  useTable,
  useSortBy,
  usePagination,
  Column,
  TableInstance,
  UseSortByColumnProps,
  UsePaginationInstanceProps,
  UsePaginationState,
  CellProps,
  UseSortByInstanceProps
} from 'react-table'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCaretDown,
  faCaretUp,
  faDownload
} from '@fortawesome/pro-solid-svg-icons'
import { Project, ProjectAndJudgeParam, PlanTaskComments } from '../types'
import { MemoizedCJShowCommentsModal } from './modals/show-comments-modal'
import { useDownloadFile } from './use-download-file'
import { Link } from 'react-router-dom'

interface Artefact {
  timeOnArtefact: number
  rankOrder: string
  artefactKey: string
  initialOrder: string
}

interface Task {
  id: string
  pack: number
  judge: string
  judgeName: string
  artefacts: Artefact[]
  projectId: string
  owner: string
  taskTime: number
  completedDate: string
  status: string
  comments: string
}

interface ProjectCommentsListing extends Project {
  tasks: Task[]
  basis: string
  categories: string[]
  packsPerArtefact: number
}

// Extend TableInstance and ColumnInstance to include sorting and pagination props
type TableInstanceWithFeatures<T extends object> = TableInstance<T> &
  UseSortByInstanceProps<T> &
  UsePaginationInstanceProps<T> &
  UsePaginationState<T>

type ColumnInstanceWithSorting<T extends object> = Column<T> &
  UseSortByColumnProps<T>

export const ListingPage: React.FC = () => {
  const bearerToken = useGetBearerToken()
  const { config } = useConfig()

  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [popupText, setPopupText] = useState<string>('')
  const { match } = useReactRouter<ProjectAndJudgeParam>()

  const projectTaskGetURL = useMemo(() => {
    let url = `${config.apiUrl}/projects/${match.params.id}/comments?completed=1`
    if (match.params.judgeId) {
      url += `&judgeId=${encodeURIComponent(match.params.judgeId)}`
    }
    return url
  }, [config.apiUrl, match.params.id, match.params.judgeId])

  const getTasksMemo = useMemo(
    () => ({
      url: projectTaskGetURL,
      headers: {
        Authorization: `Bearer ${bearerToken}`
      }
    }),
    [projectTaskGetURL, bearerToken]
  )

  const getProjectJudgeTasks = useAsyncTaskAxios<
    AxiosResponse<ProjectCommentsListing>
  >(axios, getTasksMemo)
  useAsyncRun(getProjectJudgeTasks)

  const project = getProjectJudgeTasks.result?.data || null
  const getEarlyOutcomesMemo = useMemo(() => {
    return {
      url: `${config.apiUrl}/projects/${
        match.params.id
      }/tasks/csv?completedTasks=${project ? project.completedTasks : 0}`,
      headers: {
        Authorization: `Bearer ${bearerToken}`
      }
    }
  }, [config, bearerToken, match.params.id, project?.completedTasks, project])

  const getEarlyOutcomesTask = useAsyncTaskAxios<AxiosResponse>(
    axios,
    getEarlyOutcomesMemo
  )
  const downloadJudgeTaskReport = () => {
    getEarlyOutcomesTask.start()
  }
  useEffect(() => {
    if (getEarlyOutcomesTask.result) {
      earlyOutcomesDownload.start()
    }
  }, [getEarlyOutcomesTask.result])
  const earlyOutcomesDownload = useDownloadFile({
    fileUrl: `${config.apiUrl}/artefacts/${encodeURIComponent(
      `earlyoutcomes/${match.params.id}${
        project ? `-${project.completedTasks}` : ''
      }.csv`
    )}`,
    fileName: `${match.params.id}${
      project ? `-${project.completedTasks}` : ''
    }-RAW.csv`
  })
  const data = useMemo(() => {
    if (!project || !project.tasks || project.tasks.length === 0) {
      return []
    }

    return project.tasks.map((task, index) => ({
      sno: index + 1,
      judgeName: task.judgeName,
      artefacts: task.artefacts.map(a => a.artefactKey.split('/').pop() || ''),
      initialOrder: task.artefacts.map(a => a.initialOrder.toString()),
      rankOrder: task.artefacts.map(a => a.rankOrder.toString()),
      completedDate: task.completedDate,
      comments: task.comments
    }))
  }, [project])

  const columns: Column<PlanTaskComments>[] = useMemo(
    () => [
      {
        Header: 'S.no',
        accessor: 'sno',
        headerProps: {
          className: 'text-uppercase'
        },
        cellProps: {
          className: ''
        }
      },
      {
        Header: 'Judge Name',
        accessor: 'judgeName',
        headerProps: {
          className: 'text-uppercase'
        },
        cellProps: {
          className: ''
        }
      },
      {
        Header: 'Artefacts',
        accessor: 'artefacts',
        headerProps: {
          className: 'text-uppercase w-200'
        },
        cellProps: {
          className: 'w-200'
        },
        Cell: ({ cell }: CellProps<PlanTaskComments>) => {
          return (
            <>
              {cell.value.map((artifact: string, index: number) => (
                <React.Fragment key={index}>
                  {artifact}
                  <br />
                </React.Fragment>
              ))}
            </>
          )
        }
      },
      {
        Header: 'Initial Order',
        accessor: 'initialOrder',
        headerProps: {
          className: 'text-uppercase'
        },
        cellProps: {
          className: ''
        },
        Cell: ({ cell }: CellProps<PlanTaskComments>) => {
          return (
            <>
              {cell.value.map((inOrder: string, index: number) => (
                <React.Fragment key={index}>
                  {inOrder}
                  <br />
                </React.Fragment>
              ))}
            </>
          )
        }
      },
      {
        Header: 'Rank Order',
        accessor: 'rankOrder',
        headerProps: {
          className: 'text-uppercase'
        },
        cellProps: {
          className: ''
        },
        Cell: ({ cell }: CellProps<PlanTaskComments>) => {
          return (
            <>
              {cell.value.map((rankOrder: string, index: number) => (
                <React.Fragment key={index}>
                  {rankOrder}
                  <br />
                </React.Fragment>
              ))}
            </>
          )
        }
      },
      {
        Header: 'Date & Time',
        accessor: 'completedDate',
        headerProps: {
          className: 'text-uppercase'
        },
        cellProps: {
          className: ''
        },
        Cell: ({ cell }: CellProps<PlanTaskComments>) => {
          let completedDate = new Date(cell.value)
          return <>{format(completedDate, 'MMM dd, yyyy, HH:mm')}</>
        }
      },
      {
        Header: 'Comments',
        accessor: 'comments',
        headerProps: {
          className: 'text-center text-uppercase w-300'
        },
        cellProps: {
          className: 'w-300'
        },
        Cell: ({ cell }: CellProps<PlanTaskComments>) => {
          return (
            <div>
              {cell?.value?.length > 30 ? (
                <>
                  {cell.value.slice(0, 30)}...
                  <button
                    onClick={() => handleReadMore(cell.value)}
                    className="text-primary"
                    style={{
                      marginLeft: '5px',
                      textDecoration: 'underline',
                      background: 'none',
                      border: 'none',
                      cursor: 'pointer'
                    }}
                  >
                    Read More
                  </button>
                </>
              ) : (
                cell.value
              )}
            </div>
          )
        }
      }
    ],
    [project]
  )

  const handleReadMore = (text: string) => {
    setPopupText(text)
    setIsOpen(true)
  }

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page, // Comes from usePagination
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state: { pageIndex }
  } = useTable<PlanTaskComments>(
    {
      columns,
      data,
      initialState: {
        sortBy: [
          {
            id: 'completedDate', // Column ID to sort by (must match the `accessor` key)
            desc: true // `false` for ascending, `true` for descending
          }
        ],
        pageIndex: 0,
        pageSize: 5
      }
      // set page size, no need to put pageIndex in initialState anymore
    },
    useSortBy,
    usePagination
  ) as TableInstanceWithFeatures<PlanTaskComments>
  return (
    <div className="bg-light py-4 px-3">
      <Container>
        <Row>
          <div className="col">
            <p>
              <span>
                <Link to={`/projects/${match.params.id}/judges`}>
                  {typeof project != 'undefined' &&
                  project !== null &&
                  project?.name?.length > 0
                    ? project?.name?.length > 20
                      ? project.name.slice(0, 20) + `...`
                      : project.name
                    : 'Project detail page'}{' '}
                  &gt;{' '}
                </Link>
              </span>{' '}
              <span>&nbsp; Comments Listing </span>
            </p>
          </div>
          <div className="col text-right">
            {pageCount > 0 && (
              <Button
                color="primary"
                id="link-export"
                data-testid="btn-export-commments"
                onClick={() => downloadJudgeTaskReport()}
              >
                <FontAwesomeIcon icon={faDownload} />
                <span className="ml-2">Export</span>
              </Button>
            )}
          </div>
        </Row>
        <Row>
          <h2>{project?.name}</h2>
        </Row>
        <Row>
          <div className="col">
            <p>
              <span>Pack Size: {project?.packSize}</span> |{' '}
              <span>Packs per Artefacts: {project?.packsPerArtefact}</span>
            </p>
          </div>
          <div className="col text-right">
            {pageCount > 0 && <p className="text-primary">CSV Only</p>}
          </div>
        </Row>

        <Row>
          <Table striped className="rounded mb-4" {...getTableProps()}>
            <thead className="text-primary bg-light font-weight-bold">
              {headerGroups.map(
                (headerGroup: {
                  getHeaderGroupProps: () => JSX.IntrinsicAttributes &
                    React.ClassAttributes<HTMLTableRowElement> &
                    React.HTMLAttributes<HTMLTableRowElement>
                  headers: any[]
                }) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map(column => {
                      const col = (column as unknown) as ColumnInstanceWithSorting<
                        PlanTaskComments
                      >
                      return (
                        <th
                          {...column.getHeaderProps(
                            (column as any).getSortByToggleProps(
                              (column as any).headerProps
                            )
                          )}
                        >
                          {column.render('Header')}
                          <span>
                            {col.isSorted ? (
                              col.isSortedDesc ? (
                                <FontAwesomeIcon
                                  className="ml-2"
                                  icon={faCaretDown}
                                />
                              ) : (
                                <FontAwesomeIcon
                                  className="ml-2"
                                  icon={faCaretUp}
                                />
                              )
                            ) : null}
                          </span>
                        </th>
                      )
                    })}
                  </tr>
                )
              )}
            </thead>
            <tbody {...getTableBodyProps()}>
              {getProjectJudgeTasks.result ? (
                Array.isArray(page) && page.length > 0 ? (
                  page.map(row => {
                    prepareRow(row)
                    return (
                      <tr {...row.getRowProps()}>
                        {row.cells.map(
                          (cell: {
                            getCellProps: (
                              arg0: any
                            ) => JSX.IntrinsicAttributes &
                              React.ClassAttributes<HTMLTableDataCellElement> &
                              React.TdHTMLAttributes<HTMLTableDataCellElement>
                            column: any
                            render: (
                              arg0: string
                            ) =>
                              | boolean
                              | React.ReactChild
                              | React.ReactFragment
                              | React.ReactPortal
                              | null
                              | undefined
                          }) => (
                            <td
                              {...cell.getCellProps(
                                (cell.column as any).cellProps
                              )}
                            >
                              {cell.render('Cell')}
                            </td>
                          )
                        )}
                      </tr>
                    )
                  })
                ) : (
                  <tr>
                    <td
                      colSpan={columns.length}
                      style={{ textAlign: 'center' }}
                    >
                      No data available
                    </td>
                  </tr>
                )
              ) : (
                <tr>
                  <td colSpan={columns.length} style={{ textAlign: 'center' }}>
                    Loading...
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </Row>
        {pageCount > 0 && (
          <Row className="pagination align-items-center">
            <Col className="text-left">
              <Button
                color="primary"
                onClick={() => gotoPage(0)}
                disabled={!canPreviousPage}
              >
                {'<<'}
              </Button>{' '}
              <Button
                color="primary"
                onClick={() => previousPage()}
                disabled={!canPreviousPage}
              >
                {'<'}
              </Button>{' '}
              <Button
                color="primary"
                onClick={() => nextPage()}
                disabled={!canNextPage}
              >
                {'>'}
              </Button>{' '}
              <Button
                color="primary"
                onClick={() => gotoPage(pageCount - 1)}
                disabled={!canNextPage}
              >
                {'>>'}
              </Button>
            </Col>
            <Col className="text-right">
              <span>
                Page{' '}
                <strong>
                  {pageIndex + 1} of {pageCount}
                </strong>
              </span>
            </Col>
          </Row>
        )}
      </Container>
      <MemoizedCJShowCommentsModal
        isOpen={isOpen}
        title={'Comments'}
        message={popupText}
        onDismiss={() => {
          setIsOpen(!isOpen)
          setPopupText('')
        }}
      />
    </div>
  )
}
