import React, { FC, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCaretDown,
  faCloudDownloadAlt,
  faFilePdf,
  faScroll,
  faGavel,
  faUsers,
  faComment
} from '@fortawesome/pro-solid-svg-icons'
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap'
import useReactRouter from 'use-react-router'

export interface CompletedActionProps {
  projectId: string
  rankingAvailable: boolean
  onDownloadRawDataClick: () => void
  onDownloadArtefactDataClick: () => void
  onDownloadJudgeDataClick: () => void
  onManageAdminsClick: () => void
}

export const CompletedActionsDropDown: FC<CompletedActionProps> = ({
  projectId,
  onDownloadRawDataClick,
  onDownloadArtefactDataClick,
  onDownloadJudgeDataClick,
  onManageAdminsClick,
  rankingAvailable
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const { history } = useReactRouter()
  const toggleDropDown = () => setDropdownOpen(prevState => !prevState)

  return (
    <Dropdown isOpen={dropdownOpen} toggle={toggleDropDown}>
      <DropdownToggle color="link" className="text-primary bg-none p-0">
        Actions
        <FontAwesomeIcon icon={faCaretDown} className="ml-2" />
      </DropdownToggle>
      <DropdownMenu right className="mt-3">
        {rankingAvailable && (
          <>
            <DropdownItem
              onClick={() => {
                history.push(`/projects/${projectId}/artefacts?print=true`)
              }}
              className="text-left py-3 font-weight-bold mr-4 "
            >
              <FontAwesomeIcon icon={faFilePdf} className="mr-3" fixedWidth />
              Print / Save (PDF)
            </DropdownItem>
            <DropdownItem
              onClick={() => onDownloadArtefactDataClick()}
              className="text-left py-3 font-weight-bold mr-4 "
            >
              <FontAwesomeIcon icon={faScroll} className="mr-3" fixedWidth />
              Ranked artefact data (CSV)
            </DropdownItem>
          </>
        )}
        <DropdownItem
          onClick={() => onDownloadJudgeDataClick()}
          className="text-left py-3 font-weight-bold mr-4 "
        >
          <FontAwesomeIcon icon={faGavel} className="mr-3" fixedWidth />
          Judge data (CSV)
        </DropdownItem>
        <DropdownItem
          onClick={() => onDownloadRawDataClick()}
          className="text-left py-3 font-weight-bold mr-4 "
        >
          <FontAwesomeIcon
            icon={faCloudDownloadAlt}
            className="mr-3"
            fixedWidth
          />
          Raw outcome data (CSV)
        </DropdownItem>
        <DropdownItem
          onClick={() => {
            history.push(`/listing/${projectId}`)
          }}
          className="text-left py-3 font-weight-bold mr-4 "
        >
          <FontAwesomeIcon icon={faComment} className="mr-3" fixedWidth />
          View Comments
        </DropdownItem>
        <DropdownItem divider />
        <DropdownItem
          className="py-3 text-left font-weight-bold "
          onClick={() => onManageAdminsClick()}
        >
          <FontAwesomeIcon icon={faUsers} className="mr-3" fixedWidth />
          Manage Admins
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  )
}
