import React, { useMemo, useState, useCallback, useEffect } from 'react'
import {
  Button,
  Row,
  Col,
  Container,
  TabContent,
  TabPane,
  Label,
  FormGroup,
  DropdownMenu,
  DropdownItem,
  Input
} from 'reactstrap'
import axios, { AxiosResponse } from 'axios'
import { Link } from 'react-router-dom'
import querystring from 'querystring'
import useReactRouter from 'use-react-router'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faPlus,
  faDraftingCompass,
  faStopwatch,
  faCheck,
  faTrash,
  faArrowRotateBackward
} from '@fortawesome/pro-solid-svg-icons'
import { useAsyncRun, useAsyncTaskAxios } from 'react-hooks-async'
import emptyListDraft from '../assets/icon-draft.svg'
import emptyListCompleted from '../assets/icon-completed.svg'
import emptyListInProgress from '../assets/icon-inprogress.svg'
import { useAuth0 } from '../Auth/auth'
import { Project } from '../types'
import { Can } from './can'
import { SimpleMessage } from './shared/simple-message/simple-message'
import { SimpleErrorMessage } from './shared/simple-message/simple-error-message'
import { useConfig } from '../use-remote-config'
import { useGetBearerToken } from './use-get-bearer-token'
import { TabList } from './shared/tabs/tab-list'
import { Tab } from './shared/tabs/subcomponents/tab'
import { TabContentLabelWithCount } from './tab-content-label-with-count'
import { ProjectDraftRow } from './shared/project-rows/project-list-draft-row'
import { ProjectInProgressRow } from './shared/project-rows/project-list-inprogress-row'
import { ProjectCompletedRow } from './shared/project-rows/project-list-completed-row'
import { MemoizedCJConfirmationModal } from './modals/confirmation-modal'
import { ActionsDropDown } from './project-view/actions-dropdown'

const NO_PROJECT_MSG =
  'You can create a new project with the "New Project" button at the top.'

export const MyProjects = (): JSX.Element => {
  const bearerToken = useGetBearerToken()
  const { config } = useConfig()
  const { user } = useAuth0()
  const { location } = useReactRouter()
  const statusFilter = useMemo<string>(() => {
    const qs = querystring.parse(
      location.search.startsWith('?')
        ? location.search.slice(1)
        : location.search
    )
    if (!qs.status) {
      return 'DRAFT'
    }
    if (Array.isArray(qs.status)) {
      return qs.status[0]
    }
    return qs.status
  }, [location])

  const [invalidateGetProjectToggle, setInvalidateGetProjectToggle] = useState(
    false
  )
  const [showConfirmationToggle, setShowConfirmationToggle] = useState(false)
  const [selectedProjects, setSelectedProjects] = useState<Project[]>([])

  const [loader, setLoader] = useState(false)
  const listProjectMemo = useMemo(() => {
    return {
      url: `${config.apiUrl}/projects?role=ADMIN&role=OWNER`,
      headers: {
        Authorization: `Bearer ${bearerToken}`
      }
    }
  }, [config.apiUrl, bearerToken, invalidateGetProjectToggle])

  let listProjectsTask = useAsyncTaskAxios<AxiosResponse<Project[]>>(
    axios,
    listProjectMemo
  )
  useAsyncRun(config && bearerToken && listProjectsTask)

  const draftProjects = useMemo(() => {
    if (!listProjectsTask.result) {
      return []
    }
    return listProjectsTask.result.data.filter(
      x => x.status === 'DRAFT' && x.isDeleted === false
    )
  }, [listProjectsTask.result])

  const inProgresProjects = useMemo(() => {
    if (!listProjectsTask.result) {
      return []
    }
    return listProjectsTask.result.data.filter(
      x => x.status === 'INPROGRESS' && x.isDeleted === false
    )
  }, [listProjectsTask.result])

  const completeProjects = useMemo(() => {
    if (!listProjectsTask.result) {
      return []
    }

    return listProjectsTask.result.data.filter(
      x => x.status === 'COMPLETE' && x.isDeleted === false
    )
  }, [listProjectsTask.result])

  const deletedProjects = useMemo(() => {
    if (!listProjectsTask.result) {
      return []
    }
    return listProjectsTask.result.data.filter(x => x.isDeleted === true)
  }, [listProjectsTask.result])

  const operation = statusFilter != 'DELETED' ? 'Delete' : 'Restore'

  const confirmationMsg =
    statusFilter != 'DELETED'
      ? `Warning: After 30 days, all data will be permanently purged and cannot be restored`
      : `Are you sure want to ${operation.toLowerCase()} project(s) ? `

  const addOrRemoveProject = (project: Project, type: string = 'checkbox') => {
    let isDeleted = statusFilter != 'DELETED' ? true : false
    if (type === 'checkbox') {
      if (selectedProjects.some(proj => proj.id === project.id)) {
        setTimeout(
          () =>
            setSelectedProjects(prev =>
              prev.filter(prevProj => prevProj.id !== project.id)
            ),
          0
        )
      } else {
        setTimeout(
          () =>
            setSelectedProjects(prev => [
              ...prev,
              { ...project, isDeleted: isDeleted }
            ]),
          0
        )
      }
    } else {
      setSelectedProjects([{ ...project, isDeleted: isDeleted }])
      toggleConfirmationModal()
    }
  }

  const handleCheckAll = (records: Project[]) => {
    if (selectedProjects.length === records.length) {
      setSelectedProjects([])
    } else {
      let isDeleted = statusFilter != 'DELETED' ? true : false
      const updatedProjects = records.map(record => ({
        ...record,
        isDeleted: isDeleted
      }))
      setSelectedProjects(updatedProjects)
    }
  }

  const getUrl = `${config.apiUrl}/projects/${selectedProjects[0]?.id}`
  const updateProjectMemo = useMemo(() => {
    return {
      url: getUrl,
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${bearerToken}`
      }
    }
  }, [getUrl, bearerToken])
  const updateProjectTask = useAsyncTaskAxios<AxiosResponse<Project>>(
    axios,
    updateProjectMemo
  )
  const triggerUpdate = (selectedProjects: Project[]) => {
    setLoader(true)
    updateProjectTask.start({
      data: selectedProjects
    })
    //toggleConfirmationModal();
  }
  useEffect(() => {
    if (updateProjectTask.result) {
      setLoader(false)
      setSelectedProjects([])
      listProjectsTask.start()
    }
  }, [updateProjectTask.result])

  const toggleConfirmationModal = () => {
    setShowConfirmationToggle(!showConfirmationToggle)
  }

  return (
    <>
      <div className="bg-light py-45 px-3 ">
        <Container>
          <Row className=" mb-45  ">
            <Col>
              <h1 className="h4 font-weight-bold mb-0">Projects</h1>
            </Col>
            <Col className="text-right">
              {' '}
              <Can
                userRoles={user ? user.roles : []}
                perform="project:create"
                yes={(): JSX.Element => (
                  <Link to="/createProject/1">
                    <Button
                      color="primary"
                      id="link-create"
                      data-testid="btn-new-project"
                    >
                      <FontAwesomeIcon icon={faPlus} />
                      <span className="ml-2">New project</span>
                    </Button>
                  </Link>
                )}
                no={(): JSX.Element => <></>}
              />
            </Col>
          </Row>
          <Row>
            <Col xs="auto" className="text-left font-weight-bold">
              <TabList>
                <Tab autoSize to="/projects?status=DRAFT">
                  <TabContentLabelWithCount
                    label="Draft"
                    isActive={statusFilter === 'DRAFT'}
                    icon={faDraftingCompass}
                    count={draftProjects.length}
                    countPending={listProjectsTask.pending}
                  />
                </Tab>
                <Tab autoSize to="/projects?status=INPROGRESS">
                  <TabContentLabelWithCount
                    label="In Progress"
                    isActive={statusFilter === 'INPROGRESS'}
                    icon={faStopwatch}
                    count={inProgresProjects.length}
                    countPending={listProjectsTask.pending}
                  />
                </Tab>
                <Tab autoSize to="/projects?status=COMPLETE">
                  <TabContentLabelWithCount
                    label="Completed"
                    isActive={statusFilter === 'COMPLETE'}
                    icon={faCheck}
                    count={completeProjects.length}
                    countPending={listProjectsTask.pending}
                  />
                </Tab>
                <Tab autoSize to="/projects?status=DELETED">
                  <TabContentLabelWithCount
                    label="Trash"
                    isActive={statusFilter === 'DELETED'}
                    icon={faTrash}
                    count={deletedProjects.length}
                    countPending={listProjectsTask.pending}
                  />
                </Tab>
              </TabList>
            </Col>
          </Row>
        </Container>
      </div>
      <Container className="py-45">
        <Can
          userRoles={user ? user.roles : []}
          perform="project:list"
          yes={(): JSX.Element => (
            <>
              {listProjectsTask.error && (
                <SimpleErrorMessage
                  title=" Failed to get project list"
                  message="Please refresh the page, and if the problem persists contact your system administrator."
                  allowPageRefresh
                />
              )}

              <TabContent activeTab={statusFilter}>
                <TabPane tabId="DRAFT">
                  {listProjectsTask.error == null &&
                    !listProjectsTask.pending &&
                    draftProjects.length === 0 && (
                      <SimpleMessage
                        className="mb-5"
                        title="You have no draft projects"
                        message={NO_PROJECT_MSG}
                        icon={<img src={emptyListDraft} alt="No projects" />}
                      />
                    )}
                  {listProjectsTask.error == null &&
                    !listProjectsTask.pending &&
                    draftProjects.length != 0 && (
                      <Container>
                        <Row>
                          <Col className="text-left ml-4">
                            <FormGroup check className="checkbox">
                              <Input
                                data-testid="check-all-drafts"
                                checked={
                                  draftProjects.length > 0 &&
                                  selectedProjects.length ===
                                    draftProjects.length
                                }
                                onChange={() => handleCheckAll(draftProjects)}
                                type="checkbox"
                              />
                              <Label check>Select All</Label>
                            </FormGroup>
                          </Col>

                          <Col className="text-right">
                            <ActionsDropDown>
                              {selectedProjects.length > 0 && (
                                <DropdownMenu right className="mt-3">
                                  <DropdownItem
                                    className="py-3 text-left font-weight-bold "
                                    data-testid="select-all-drafts-delete"
                                    onClick={e => {
                                      e.preventDefault()
                                      toggleConfirmationModal()
                                    }}
                                  >
                                    <FontAwesomeIcon
                                      icon={faTrash}
                                      className="mr-3"
                                      fixedWidth
                                    />
                                    Delete Projects
                                  </DropdownItem>
                                </DropdownMenu>
                              )}
                            </ActionsDropDown>
                          </Col>
                        </Row>
                      </Container>
                    )}
                  {draftProjects.map(project => (
                    <Link
                      key={project.id}
                      className="no-text-hover"
                      to={`/projects/${project.id}/artefacts`}
                    >
                      <ProjectDraftRow
                        {...project}
                        triggerRemoveRow={() => {
                          setInvalidateGetProjectToggle(
                            !invalidateGetProjectToggle
                          )
                        }}
                        className="mb-5"
                        toggleConfirmationModal={toggleConfirmationModal}
                        selectedProjects={selectedProjects}
                        addOrRemoveProject={addOrRemoveProject}
                        operation={operation}
                      />
                    </Link>
                  ))}
                </TabPane>
                <TabPane tabId="INPROGRESS">
                  {listProjectsTask.error == null &&
                    !listProjectsTask.pending &&
                    inProgresProjects.length === 0 && (
                      <SimpleMessage
                        className="mb-5"
                        title="You have no in progress projects"
                        message={NO_PROJECT_MSG}
                        icon={
                          <img src={emptyListInProgress} alt="No projects" />
                        }
                      />
                    )}
                  {inProgresProjects.map(project => (
                    <Link
                      key={project.id}
                      className="no-text-hover"
                      to={`/projects/${project.id}/judges`}
                    >
                      <ProjectInProgressRow {...project} className="mb-5" />
                    </Link>
                  ))}
                </TabPane>
                <TabPane tabId="COMPLETE">
                  {listProjectsTask.error == null &&
                    !listProjectsTask.pending &&
                    completeProjects.length === 0 && (
                      <SimpleMessage
                        className="mb-5"
                        title="You have no completed projects"
                        message={NO_PROJECT_MSG}
                        icon={
                          <img src={emptyListCompleted} alt="No projects" />
                        }
                      />
                    )}
                  {listProjectsTask.error == null &&
                    !listProjectsTask.pending &&
                    completeProjects.length != 0 && (
                      <Container>
                        <Row>
                          <Col className="text-left ml-4">
                            <FormGroup check className="checkbox">
                              <Input
                                data-testid="check-all-completed"
                                checked={
                                  completeProjects.length > 0 &&
                                  selectedProjects.length ===
                                    completeProjects.length
                                }
                                onChange={() =>
                                  handleCheckAll(completeProjects)
                                }
                                type="checkbox"
                              />
                              <Label check>Select All</Label>
                            </FormGroup>
                          </Col>
                          <Col className="text-right">
                            <ActionsDropDown>
                              {selectedProjects.length > 0 && (
                                <DropdownMenu right className="mt-3">
                                  <DropdownItem
                                    data-testid="select-all-completed-delete"
                                    className="py-3 text-left font-weight-bold "
                                    onClick={e => {
                                      e.preventDefault()
                                      toggleConfirmationModal()
                                    }}
                                  >
                                    <FontAwesomeIcon
                                      icon={faTrash}
                                      className="mr-3"
                                      fixedWidth
                                    />
                                    Delete Projects
                                  </DropdownItem>
                                </DropdownMenu>
                              )}
                            </ActionsDropDown>
                          </Col>
                        </Row>
                      </Container>
                    )}
                  {completeProjects.map(project => (
                    <Link
                      key={project.id}
                      className="no-text-hover"
                      to={`/projects/${project.id}/artefacts`}
                    >
                      <ProjectCompletedRow
                        {...project}
                        className="mb-5"
                        toggleConfirmationModal={toggleConfirmationModal}
                        selectedProjects={selectedProjects}
                        addOrRemoveProject={addOrRemoveProject}
                        operation={operation}
                      />
                    </Link>
                  ))}
                </TabPane>
                <TabPane tabId="DELETED">
                  {listProjectsTask.error == null &&
                    !listProjectsTask.pending &&
                    deletedProjects.length === 0 && (
                      <SimpleMessage
                        className="mb-5"
                        title="You have no deleted projects"
                        message={NO_PROJECT_MSG}
                        icon={
                          <img src={emptyListCompleted} alt="No projects" />
                        }
                      />
                    )}
                  {listProjectsTask.error == null &&
                    !listProjectsTask.pending &&
                    deletedProjects.length != 0 && (
                      <Container>
                        <Row>
                          <Col className="text-left ml-4">
                            <FormGroup check className="checkbox">
                              <Input
                                data-testid="check-all-deleted"
                                checked={
                                  deletedProjects.length > 0 &&
                                  selectedProjects.length ===
                                    deletedProjects.length
                                }
                                onChange={() => handleCheckAll(deletedProjects)}
                                type="checkbox"
                              />
                              <Label check>Select All</Label>
                            </FormGroup>
                          </Col>
                          <Col className="text-right">
                            <ActionsDropDown>
                              {selectedProjects.length > 0 && (
                                <DropdownMenu right className="mt-3">
                                  <DropdownItem
                                    data-testid="restore-all-deleted"
                                    className="py-3 text-left font-weight-bold "
                                    onClick={e => {
                                      e.preventDefault()
                                      toggleConfirmationModal()
                                    }}
                                  >
                                    <FontAwesomeIcon
                                      icon={faArrowRotateBackward}
                                      className="mr-3"
                                      fixedWidth
                                    />
                                    Restore Projects
                                  </DropdownItem>
                                </DropdownMenu>
                              )}
                            </ActionsDropDown>
                          </Col>
                        </Row>
                      </Container>
                    )}
                  {deletedProjects.map(project => (
                    <>
                      {project.status === 'COMPLETE' && (
                        <ProjectCompletedRow
                          {...project}
                          className="mb-5"
                          toggleConfirmationModal={toggleConfirmationModal}
                          selectedProjects={selectedProjects}
                          addOrRemoveProject={addOrRemoveProject}
                          operation={operation}
                        />
                      )}
                      {project.status === 'DRAFT' && (
                        <ProjectDraftRow
                          {...project}
                          triggerRemoveRow={() => {
                            setInvalidateGetProjectToggle(
                              !invalidateGetProjectToggle
                            )
                          }}
                          className="mb-5"
                          toggleConfirmationModal={toggleConfirmationModal}
                          selectedProjects={selectedProjects}
                          addOrRemoveProject={addOrRemoveProject}
                          operation={operation}
                        />
                      )}
                    </>
                  ))}
                </TabPane>
              </TabContent>
            </>
          )}
          no={(): JSX.Element => <></>}
        />
      </Container>
      <MemoizedCJConfirmationModal
        project={selectedProjects}
        loader={loader}
        isOpen={showConfirmationToggle}
        onConfirmation={() => triggerUpdate(selectedProjects)}
        title={confirmationMsg}
        selectedProjectsLength={selectedProjects.length}
        buttonTitle={operation}
        onDismiss={() => {
          toggleConfirmationModal()
          setSelectedProjects([])
        }}
      />
    </>
  )
}
