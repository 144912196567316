export async function getBinary(file: File): Promise<string | ArrayBuffer> {
  return new Promise<string | ArrayBuffer>((resolve, reject): void => {
    const reader = new FileReader()
    reader.addEventListener('abort', (): void =>
      reject(new Error('file reading was aborted'))
    )
    reader.addEventListener('error', (): void =>
      reject(new Error('file reading has failed'))
    )
    reader.addEventListener('load', (): void => {
      if (reader.result !== null) {
        resolve(reader.result)
      }
    })

    reader.readAsArrayBuffer(file)
  })
}
