import React, { FC, useMemo, useEffect, useState } from 'react'
import {
  Row,
  Col,
  Progress,
  DropdownMenu,
  DropdownItem,
  Button
} from 'reactstrap'
import { Judge } from '../../../../types'
import {
  faPause,
  faPlay,
  faPeopleCarry,
  faComment
} from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ActionsDropDown } from '../../../project-view/actions-dropdown'
import { useConfig } from '../../../../use-remote-config'
import { useGetBearerToken } from '../../../use-get-bearer-token'
import { useAsyncTaskAxios } from 'react-hooks-async'
import axios, { AxiosResponse } from 'axios'
import { EllipsisSpinner } from '../../ellipsis-spinner/ellipsis-spinner'
import { CJErrorModal } from '../../../modals/simple-error-modal'
import { TaskProgress } from '../../subcomponents/task-progress'

import { Avatar } from '../../avatar/avatar'
import { ProfileInitials } from '../../profile-initials/ProfileInitials'
import { Link, Router, useHistory } from 'react-router-dom'

interface JudgeProgressRowProps {
  judge: Judge
  index: number
  projectId: string
  onPauseChanged: (isPaused: boolean) => void
  onSelectReallocate: () => void
}

export const JudgeProgressRow: FC<JudgeProgressRowProps> = ({
  index,
  judge,
  projectId,
  onSelectReallocate,
  onPauseChanged
}): JSX.Element => {
  const { config } = useConfig()
  const bearerToken = useGetBearerToken()
  const [errorCleared, setErrorCleared] = useState(false)
  const history = useHistory()

  const pauseJudgeMemo = useMemo(() => {
    return {
      url: `${config.apiUrl}/projects/${projectId}/judges/${encodeURIComponent(
        judge.user_id
      )}`,
      method: 'PATCH',
      headers: {
        Authorization: `Bearer ${bearerToken}`
      }
    }
  }, [bearerToken, judge, config.apiUrl])

  const pauseJudgeTask = useAsyncTaskAxios<AxiosResponse<void>>(
    axios,
    pauseJudgeMemo
  )

  const togglePause = () => {
    pauseJudgeTask.start({
      data: { isPaused: !judge.isPaused }
    })
  }

  useEffect(() => {
    if (pauseJudgeTask.result) {
      onPauseChanged(!judge.isPaused)
    }
  }, [pauseJudgeTask.result, judge.isPaused])

  useEffect(() => {
    setErrorCleared(false)
  }, [pauseJudgeTask.error])
  const LinkToPage = `/listing/${projectId}/${judge.user_id}/`
  const goToComments = () => {
    history.push(LinkToPage)
  }
  return (
    <>
      <Row
        className={`align-items-center  mx-0 px-25 py-4 ${
          index !== 0 ? 'border-top ' : ''
        } ${judge.isPaused || judge.isPlaceholder ? 'bg-light' : ''}`}
        key={judge.user_id}
      >
        <Col
          xs="auto"
          style={{ minWidth: '47px' }}
          className=" text-center font-larger font-weight-bold px-0"
        >
          {judge.assignedJudgePlace}
        </Col>
        <Col xs="auto" className="px-1">
          <Avatar
            emailVerified={
              judge.email_verified ||
              (judge.isPlaceholder !== undefined && judge.isPlaceholder)
            }
            pictureSrc={
              judge.isPlaceholder
                ? ProfileInitials(judge.name)
                : typeof judge.picture != 'undefined' &&
                  judge.picture === '/icon-people-sq.svg'
                ? ProfileInitials(judge.name)
                : judge.picture
            }
            className={
              judge.isPaused || judge.isPlaceholder ? 'grayscale-100' : ''
            }
          />
        </Col>
        <Col xs={8}>
          <Row className="align-items-center">
            <Col style={{ maxWidth: 'calc(100% - 110px)' }}>
              <div
                title={judge.name}
                className={`font-weight-bold text-truncate overflow-hidden text-break ${
                  judge.isPlaceholder ? 'text-secondary' : ''
                }`}
              >
                {judge.isPlaceholder ? 'Not allocated' : judge.name}
              </div>
              {judge.name !== judge.email && (
                <div
                  title={judge.email}
                  className="d-none d-xl-block text-secondary font-smaller text-truncate overflow-hidden text-break"
                >
                  {judge.email}
                </div>
              )}
              {judge.completedTasks > 0 && judge.isCommentSeen === false && (
                <Link to={LinkToPage} className="text-primary">
                  New comment/s added
                </Link>
              )}
            </Col>
            {}
            {judge.isPaused && (
              <Col className="text-right" style={{ flex: '0 0 110px' }}>
                <div
                  className={`font-smaller font-weight-bold py-1 px-25 rounded bg-primary text-white text-center ${
                    judge.isPaused ? 'grayscale-100' : ''
                  }`}
                >
                  <FontAwesomeIcon icon={faPause} size="sm" className="mr-2" />
                  {'Paused'}
                </div>
              </Col>
            )}
          </Row>
        </Col>

        <Col style={{ flex: '0 0 100px' }} className="text-center ">
          {judge.isPlaceholder ? (
            <div className="h5 mb-0 text-secondary font-weight-bold">
              {judge.totalTasks}
            </div>
          ) : (
            <TaskProgress
              completedTasks={judge.completedTasks}
              totalTasks={judge.totalTasks}
            />
          )}
        </Col>
        <Col
          style={{ flex: '0 0 125px' }}
          className="text-center text-secondary"
        >
          {!judge.isPlaceholder && (
            <>
              {judge.totalTimeInSeconds && judge.completedTasks
                ? `${Math.round(
                    judge.totalTimeInSeconds / judge.completedTasks
                  )} seconds`
                : 'n/a'}
            </>
          )}
        </Col>
        {!judge.isPlaceholder && (
          <Col className="text-center">
            <Progress
              className={`progress-slim-2 ${
                judge.isPaused ? 'grayscale-100' : ''
              }`}
              max={judge.totalTasks || 0}
              value={judge.completedTasks || 0}
            />
          </Col>
        )}
        {!judge.isPlaceholder && (
          <Col
            style={{ flex: '0 0 80px' }}
            className="d-none d-xl-block font-weight-bold mb-0 text-right"
          >
            <>
              <span className="h4 font-weight-bold align-middle">
                {`${Math.round(
                  (100 / (judge.totalTasks || 1)) * (judge.completedTasks || 0)
                )}`}
              </span>
              <span className="align-middle ml-1">%</span>
            </>
          </Col>
        )}
        {judge.isPlaceholder && (
          <Col className="text-right">
            <Button
              color="primary"
              className="py-1"
              onClick={onSelectReallocate}
            >
              {' '}
              Allocate Work
            </Button>
          </Col>
        )}
        {!judge.isPlaceholder && (
          <Col className="text-right" style={{ flex: '0 0 105px' }}>
            {pauseJudgeTask.started && pauseJudgeTask.pending && (
              <EllipsisSpinner
                className="text-secondary"
                title={judge.isPaused ? 'Resuming' : 'Pausing'}
              />
            )}

            {!(pauseJudgeTask.pending && pauseJudgeTask.started) && (
              <ActionsDropDown>
                <DropdownMenu right className="mt-3">
                  {judge.completedTasks < judge.totalTasks && (
                    <DropdownItem
                      className="text-left py-3 font-weight-bold mr-45 "
                      onClick={onSelectReallocate}
                    >
                      <FontAwesomeIcon
                        icon={faPeopleCarry}
                        className="mr-3"
                        fixedWidth
                      />
                      {judge.isPlaceholder
                        ? 'Allocate work'
                        : 'Reallocate work'}
                    </DropdownItem>
                  )}
                  {!judge.isPlaceholder && (
                    <DropdownItem
                      onClick={() => {
                        togglePause()
                      }}
                      className="text-left py-3 font-weight-bold mr-45 "
                    >
                      {judge.isPaused && (
                        <>
                          <FontAwesomeIcon
                            icon={faPlay}
                            className="mr-3"
                            fixedWidth
                          />
                          Resume judge
                        </>
                      )}
                      {!judge.isPaused && (
                        <>
                          <FontAwesomeIcon
                            icon={faPause}
                            className="mr-3"
                            fixedWidth
                          />
                          Pause judge
                        </>
                      )}
                    </DropdownItem>
                  )}
                  <DropdownItem onClick={() => goToComments()}>
                    <FontAwesomeIcon
                      icon={faComment}
                      className="mr-3"
                      fixedWidth
                    />
                    View comments
                  </DropdownItem>
                </DropdownMenu>
              </ActionsDropDown>
            )}
          </Col>
        )}
      </Row>

      <CJErrorModal
        isOpen={pauseJudgeTask.error !== null && !errorCleared}
        onDismiss={() => setErrorCleared(true)}
        title={`${judge.isPaused ? 'Resuming' : 'Pausing'} judge failed`}
        message="Please try again, and if the problem persists contact your system administrator"
      />
    </>
  )
}
