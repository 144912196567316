import React, { useMemo } from 'react'
import { Row, Col, Button, Spinner } from 'reactstrap'
import { useAsyncTaskAxios } from 'react-hooks-async'
import axios, { AxiosResponse } from 'axios'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faPaperPlane,
  faCheck,
  faExclamationTriangle,
  faTimes,
  faTrash
} from '@fortawesome/pro-solid-svg-icons'

import { User } from '../../types'
import { useConfig } from '../../use-remote-config'
import { useGetBearerToken } from '../use-get-bearer-token'
import { FaCircleEnvelope } from '../shared/icons/fa-circle-envelope'

import { useProjectResourceDeleter } from '../use-project-resource-manager'
import { ProfileInitials } from '../shared/profile-initials/ProfileInitials'

interface DraftJudgeProps {
  index: number
  projectId: string
  className?: string
  user: User
  onDeleteStart: () => void
  onDeleteComplete: () => void
  onDeleteError: () => void
}

export const DraftJudge: React.FC<DraftJudgeProps> = ({
  projectId,
  onDeleteComplete,
  onDeleteError,
  onDeleteStart,
  index,
  className,
  user
}): JSX.Element => {
  const {
    user_id,
    email,
    name,
    email_verified,
    picture,
    deleteFailed,
    deleting,
    isPlaceholder
  } = user
  const { config } = useConfig()
  const bearerToken = useGetBearerToken()
  const { deleteResource } = useProjectResourceDeleter(
    projectId,
    'judges',
    user_id,
    onDeleteStart,
    onDeleteComplete,
    onDeleteError
  )

  const resendInviteMemo = useMemo(() => {
    return {
      url: `${config.apiUrl}/users/${encodeURIComponent(
        user_id
      )}/resendVerification`,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${bearerToken}`
      }
    }
  }, [user_id, bearerToken, config.apiUrl])

  const resendInviteTask = useAsyncTaskAxios<AxiosResponse<void>>(
    axios,
    resendInviteMemo
  )

  return (
    <Row
      className={`${className} ${
        deleteFailed || deleting || isPlaceholder ? 'bg-light' : ''
      } align-items-center mb-0 mx-0 border-left border-right border-bottom`}
    >
      <Col
        style={{ minWidth: '64px' }}
        xs="auto"
        className="font-larger bg-light rounded-left font-weight-bold my-0 border-right align-self-stretch align-items-center justify-content-center d-flex px-4 py-35"
      >
        {index + 1}
      </Col>
      {!isPlaceholder && (
        <>
          <Col
            xs="auto"
            style={{ minWidth: '50px' }}
            className="justify-content-center align-items-center d-flex pl-3 pr-1"
          >
            {email_verified ? (
              <img
                src={
                  typeof picture != 'undefined' &&
                  picture === '/icon-people-sq.svg'
                    ? ProfileInitials(user?.name)
                    : picture
                }
                alt="Profile"
                style={{ height: '32px' }}
                className="nav-user-profile d-inline-block "
              />
            ) : (
              <FaCircleEnvelope size="2x" />
            )}
          </Col>
        </>
      )}
      <Col>
        <div
          title={name}
          className={`font-weight-bold text-truncate overflow-hidden text-break `}
        >
          {name}
        </div>
        {name !== email && (
          <div
            title={email}
            className="d-none d-xl-block text-secondary font-smalle`r text-truncate overflow-hidden text-break"
          >
            {email}
          </div>
        )}
      </Col>
      {!email_verified && !isPlaceholder && (
        <Col
          xs="auto"
          className="justify-content-center align-items-center d-flex"
        >
          <Button
            size="sm"
            color="lightButNotTooLight"
            disabled={
              (resendInviteTask.pending && resendInviteTask.started) ||
              resendInviteTask.result !== null
            }
            className=" font-smaller px-3 py-2"
            onClick={() => {
              resendInviteTask.start()
            }}
          >
            {resendInviteTask.pending && resendInviteTask.started && (
              <Spinner data-testid="resendSpinner" size="sm" />
            )}
            {!(resendInviteTask.pending && resendInviteTask.started) && (
              <FontAwesomeIcon
                title={
                  resendInviteTask.result
                    ? 'Invite has been sent'
                    : 'Click to resend invite'
                }
                icon={resendInviteTask.result ? faCheck : faPaperPlane}
                className="mr-md-2"
              />
            )}
            {!resendInviteTask.started && resendInviteTask.result === null && (
              <span className="d-none d-md-inline-block">Resend invite</span>
            )}
            {resendInviteTask.result && (
              <span className="d-none d-md-inline-block">Invite sent</span>
            )}
          </Button>
        </Col>
      )}

      <Col
        style={{ minWidth: '64px' }}
        xs="auto"
        className="align-items-center text-right p-3 pr-4 d-flex align-items-center justify-content-end"
      >
        {!deleting && deleteFailed && (
          <span className="text-danger mr-35 font-weight-bold">
            <FontAwesomeIcon icon={faExclamationTriangle} className="mr-3" />
            {`Deleting this ${
              isPlaceholder ? 'placeholder' : 'judge'
            } has failed`}
          </span>
        )}
        {deleting && (
          <>
            <span className="font-weight-bold text-secondary mr-35">
              {`Deleting this ${isPlaceholder ? 'placeholder' : 'judge'}...`}
            </span>
            <Spinner data-testid="spinner-delete" size="sm" color="secondary" />
          </>
        )}
        {!deleting && (
          <Button
            color="link"
            data-testid="btn-delete"
            className="px-0"
            disabled={deleting}
            onClick={() => deleteResource()}
          >
            <FontAwesomeIcon icon={isPlaceholder ? faTrash : faTimes} />
          </Button>
        )}
      </Col>
    </Row>
  )
}
