import React from 'react'

import { Button, ModalProps, Spinner } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faTrash,
  faArrowRotateBackward,
  faCheck
} from '@fortawesome/pro-solid-svg-icons'
import { CJModal } from '../shared/modal/modal'
import { CJModalHeader } from '../shared/modal/subcomponents/modal-header'
import { CJModalBody } from '../shared/modal/subcomponents/modal-body'
import {
  SimpleModalMessage,
  ModalConfirmationProps
} from '../shared/modal/subcomponents/modal-message'
import { CJModalFooter } from '../shared/modal/subcomponents/modal-footer'

export const CJConfirmationModal: React.FunctionComponent<ModalProps &
  ModalConfirmationProps> = ({
  title,
  isOpen,
  loader,
  onDismiss,
  onConfirmation,
  selectedProjectsLength,
  buttonTitle,
  ...rest
}) => {
  let classColor =
    buttonTitle.toString().toLowerCase() === 'delete' &&
    selectedProjectsLength > 0
      ? 'bg-danger'
      : 'bg-primary'
  const deleteButtonColor =
    buttonTitle.toString().toLowerCase() === 'delete' &&
    selectedProjectsLength > 0
      ? 'danger'
      : 'primary'
  const loadFaIcon = () => {
    return buttonTitle.toString().toLowerCase() === 'delete' ? (
      <FontAwesomeIcon
        icon={selectedProjectsLength > 0 ? faTrash : faCheck}
        color="white"
        className="my-2"
        size="lg"
      />
    ) : (
      <FontAwesomeIcon
        icon={selectedProjectsLength > 0 ? faArrowRotateBackward : faCheck}
        color="white"
        className="my-2"
        size="lg"
      />
    )
  }
  const loadMsgSection = () => {
    return selectedProjectsLength === 0 ? (
      <SimpleModalMessage
        title={`Project(s) successfully ${buttonTitle.toLowerCase()}d`}
        message="You can always create a new project from your admin dashboard"
      />
    ) : (
      <SimpleModalMessage
        title={title}
        message={
          'You can always create a new project from your admin dashboard'
        }
      />
    )
  }
  const showButtonText = () => {
    if (loader !== true) {
      return buttonTitle
    } else {
      return <Spinner color="white" size="sm" />
    }
  }
  return (
    <CJModal
      size="sm"
      centered
      isOpen={isOpen}
      toggle={() => onDismiss()}
      {...rest}
    >
      <CJModalHeader className={classColor}>{loadFaIcon()}</CJModalHeader>
      <CJModalBody>{loadMsgSection()}</CJModalBody>
      <CJModalFooter>
        {selectedProjectsLength > 0 && (
          <Button
            data-testid="delete_button"
            color={deleteButtonColor}
            outline
            onClick={() => {
              onConfirmation()
            }}
          >
            {showButtonText()}
          </Button>
        )}
        <Button
          outline
          data-testid="close_button"
          onClick={() => {
            onDismiss()
          }}
        >
          {selectedProjectsLength > 0 ? 'Go back' : 'Close'}
        </Button>
      </CJModalFooter>
    </CJModal>
  )
}
export const MemoizedCJConfirmationModal = React.memo(CJConfirmationModal)
