import React, { useState, useMemo } from 'react'
import {
  Col,
  Row,
  CardBody,
  Card,
  DropdownMenu,
  DropdownItem,
  Input,
  Label,
  FormGroup
} from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faUsers,
  faTrash,
  faArrowRotateBackward
} from '@fortawesome/pro-solid-svg-icons'
import { Project } from '../../../types'
import { NameAndMethod } from '../../name-and-method'
import { ManageAdminModal } from '../../modals/manage-admins-modal'
import { ActionsDropDown } from '../../project-view/actions-dropdown'
import { format } from 'date-fns'

export const ProjectDraftRow: React.FC<Project &
  React.HTMLAttributes<HTMLDivElement> & { triggerRemoveRow: () => void } & {
    operation: string
  } & { addOrRemoveProject: (project: Project, type: string) => void } & {
    selectedProjects: Project[]
  } & { toggleConfirmationModal: () => void }> = ({
  className,
  triggerRemoveRow,
  addOrRemoveProject,
  selectedProjects,
  toggleConfirmationModal,
  operation,
  ...project
}): JSX.Element => {
  const [showAdminsModal, setShowAdminsModal] = useState(false)
  const isChecked =
    typeof selectedProjects != 'undefined' && selectedProjects.length > 0
      ? selectedProjects.some(proj => proj.id == project.id)
      : false
  const loadDropDownItems = () => {
    if (operation.toLowerCase() === 'delete') {
      return (
        <DropdownMenu right className="mt-3">
          <DropdownItem
            className="py-3 text-left font-weight-bold "
            data-testid="manage-admin-drafts"
            onClick={e => {
              e.preventDefault()
              setShowAdminsModal(true)
            }}
          >
            <FontAwesomeIcon icon={faUsers} className="mr-3" fixedWidth />
            Manage Admins
          </DropdownItem>
          <DropdownItem
            className="py-3 text-left font-weight-bold "
            data-testid="confirmation-modal-drafts"
            onClick={e => {
              e.preventDefault()
              addOrRemoveProject(project, 'button')
            }}
          >
            <FontAwesomeIcon icon={faTrash} className="mr-3" fixedWidth />
            {`${operation} project`}
          </DropdownItem>
        </DropdownMenu>
      )
    } else {
      return (
        <DropdownMenu right className="mt-3">
          <DropdownItem
            className="py-3 text-left font-weight-bold "
            data-testid={
              operation?.toLowerCase() === 'delete'
                ? 'confirmation-modal-drafts'
                : 'confirmation-modal-delete'
            }
            onClick={e => {
              e.preventDefault()
              addOrRemoveProject(project, 'button')
            }}
          >
            <FontAwesomeIcon
              icon={faArrowRotateBackward}
              className="mr-3"
              fixedWidth
            />
            {`${operation} project`}
          </DropdownItem>
        </DropdownMenu>
      )
    }
  }
  const deletedDate =
    Array.isArray(project.deletedLogs) && project.deletedLogs.length > 0
      ? project.deletedLogs[project.deletedLogs.length - 1].createdAt
      : new Date()
  const formattedDeletedDate = useMemo(() => {
    return deletedDate ? format(deletedDate, 'MMM dd, yyyy, hh:mm a') : '-'
  }, [project])
  return (
    <>
      <Card className={`${className} project-row`}>
        <CardBody className="p-42">
          <Row
            className="align-items-center"
            data-testid={
              operation?.toLowerCase() === 'delete'
                ? 'project-draft'
                : 'project-delete'
            }
          >
            <Col xs="auto">
              <FormGroup check className="mb-55">
                <Label check className="checkbox">
                  <Input
                    data-testid="checkbox-drafts"
                    key={project.id}
                    type="checkbox"
                    onClick={e => {
                      e.preventDefault()
                    }}
                    checked={isChecked}
                    onChange={() => addOrRemoveProject(project, 'checkbox')}
                  />
                </Label>
              </FormGroup>
            </Col>
            <Col>
              <NameAndMethod {...project} />
            </Col>
            {project.isDeleted === true && (
              <Col xs="auto" className="text-right text-dark">
                <FontAwesomeIcon icon={faTrash} className="mr-2 text-primary" />
                <span className="font-weight-bold text-dark">
                  {`Deleted on ${formattedDeletedDate}`}
                </span>
              </Col>
            )}
            {project.isDeleted === false && (
              <>
                <Col xs="auto" className="text-right">
                  <div className="text-secondary font-weight-bold">
                    Pack Size
                  </div>
                  <div className="h4 mb-0 font-weight-bold text-dark">
                    {project.packSize}
                  </div>
                </Col>
                <Col xs="auto" className="text-right ml-lg-45">
                  {project.packsPerArtefact && !project.planUploaded && (
                    <>
                      <div className="text-secondary font-weight-bold">
                        Judgements per Artefact
                      </div>
                      <div className="h4 mb-0 font-weight-bold text-dark">
                        {(project.packSize - 1) * project.packsPerArtefact}
                      </div>
                    </>
                  )}
                  {project.planUploaded && (
                    <>
                      <div className="text-secondary font-weight-bold">
                        Plan Type
                      </div>
                      <div className="h5 mb-0 font-weight-bold text-dark">
                        Manual
                      </div>
                    </>
                  )}
                </Col>
              </>
            )}
            <Col xs="auto" className="text-right ml-lg-45">
              <ActionsDropDown>{loadDropDownItems()}</ActionsDropDown>
            </Col>
          </Row>
        </CardBody>
      </Card>
      <ManageAdminModal
        project={project}
        onRemoveCurrentUser={() => {
          triggerRemoveRow()
        }}
        isOpen={showAdminsModal}
        toggle={() => setShowAdminsModal(!showAdminsModal)}
      />
    </>
  )
}
