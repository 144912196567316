import { AxiosStatic } from 'axios'
import { CredentialsOptions } from 'aws-sdk/lib/credentials'

export interface Rule {
  role: string
  permissions: string[]
}

export interface AuthConfig {
  domain: string
  client_id: string
  audience?: string
  scope?: string
  role_url: string
}
export interface AuthCallback {
  onRedirectCallback?: (appState: any) => void
}
export interface Config {
  apiUrl?: string
  bucketName?: string
  auth0Config?: AuthConfig
}
export interface ConfigState {
  loading: boolean
  config: Config
}
export interface JudgeUploaderState {
  loading: boolean
  error?: string
  completedAt?: Date
}
export type ConnectStatus = 'CONNECTED' | 'UNCONNECTED'
export type EditFields = 'NAME' | 'QUESTION' | 'METHOD' | 'DATE'
export interface PlanTopLine {
  noJudges: number
  noArtefacts: number
  catCounts: { [key: string]: number }
  packSizes: number[]
  toggleCheckError?: boolean
}
export interface JudgeMapping {
  planKey: number
  judgeId: string
}
export interface PlanSummary {
  connected: ConnectStatus
  artefactCount: number
  packSize: number
  artefactCategoryGroups: { [key: string]: string[] }
  idealSeenCount: number
  targetPacksPerArtefact?: number
  judgeCount: number
  judgeCounts: { [key: string]: number }
  packsPerJudge: { [key: string]: number }
  judgementsPerArtefact: { [key: string]: number }
  artefactSeenFrequency: { [key: string]: number }
  possiblePairFrequency: { [key: string]: number }
  artefactHash: number
  totalTasks: number
}
export interface RemovableResource {
  deleting?: boolean
  deleteFailed?: boolean
}

export interface User extends RemovableResource {
  email: string
  user_id: string
  sub: string
  picture: string
  name: string
  nickname: string
  roles: string[]
  email_verified: boolean
  isPlaceholder?: boolean
  completedTasks?: number
  totalTasks?: number
  leftWins?: number
  rightWins?: number
  minTimeInSeconds?: number
  medianTimeInSeconds?: number
  maxTimeInSeconds?: number
  totalTimeInSeconds?: number
}

export interface Judge extends User {
  totalTasks: number
  completedTasks: number
  isCommentSeen?: boolean
  totalTimeInSeconds?: number
  leftWins?: number
  rightWins?: number
  minTimeInSeconds?: number
  maxTimeInSeconds?: number
  medianTimeInSeconds?: number
  assignedJudgePlace?: number
  isPaused?: boolean
  tasksReallocated?: boolean
}
export interface JudgeAllocation extends Judge {
  toReallocate: number
}
export interface AdminUser extends User {
  isOwner: boolean
}
export interface AppState {
  targetUrl: string
}

export interface PathParam {
  id: string
}
export interface PathAndTabParam {
  id: string
  tab: string
  category?: string
}
export interface ProjectAndJudgeParam {
  id: string
  judgeId?: string
}

/**
 * Error properties.
 */
export interface Error {
  /** optional override error message */
  message?: string
}

export interface PlanTypeStepProps {
  planType?: PlanType
}
export interface UploadPlanStepProps {
  categories: string[]
  planType?: PlanType
  packSize?: number
  packsPerArtefact?: number
  tempPlanKey?: string
}

export interface State<T> {
  data: T[]
  retrievingData: boolean
  addAssets: (newAssets: T[]) => void
  getItem: (id: string) => T | undefined
  replaceItem: (newAsset: T) => void
  refresh: (newAssets: T[]) => void
  projectId: string
  resourceName: string
  idFunction: (item: T) => string
  resourceUrl: string
  getError: Error | null
  deleteStart: (item: T) => void
  deleteComplete: (item: T) => void
  deleteFailed: (item: T) => void
}

export interface NameStepProps {
  name: string
}
export interface S3File {
  name: string
  key: string
  file: File
}

export interface UploadingFile {
  name: string
  key: string
  file?: File
  uploadingState: UploadingState
}
export interface ProjectAssetAction<T> {
  type: string
  payload?: T[]
  idFunction: (item: T) => string
  deleteId?: string
}
export interface UploadingState {
  loaded: number
  total: number
  isLoading: boolean
  isComplete: boolean
  isError: boolean
  isProcessed?: boolean
}

export interface UploadAction {
  type: string
  payload: {
    newFiles?: S3File[]
    key?: string
    categories?: Map<string, string>
    keys?: string[]
    keyPrefix?: string
    progress?: {
      loaded: number
      total: number
    }
  }
}
export interface MethodStepProps {
  packSize?: number
  artefactViewCount?: number
}
export interface ArtefactView extends Artefact {
  uploadingState?: UploadingState
  tempKey?: string
}

export interface DraftArtefactView extends Artefact {
  uploadingState?: UploadingState
  file?: File
  tempKey?: string
}

export interface DateStepProps {
  endDate?: Date
}

export interface CategoryStepProps {
  categories: string[]
}

export interface BasisStepProps {
  basis: string
}
export interface WizardStepProps<T> {
  onChange?: (event: T) => void
  stepNumber: number
  currentStep: number
  data: T
  totalSteps: number
  goForward: () => void
  goBack?: () => void
}
export interface Id {
  id: string
}
export interface ProjectData {
  name: string
  basis: string
  endDate?: Date
  planType?: PlanType
  categories: string[]
  packSize?: number
  packsPerArtefact?: number
  tempPlanKey?: string
  id?: string
}
export interface ProjectViewProps {
  project: Project
  onProjectUpdate?: (updated: Project) => void
  onProjectStart?: () => void
}

export interface DeletedLogs {
  userID: string
  isDeleted: boolean
  createdAt: number
}

export interface Project extends ProjectData, Id {
  id: string
  packSize: number
  status: ProjectStatus
  userRole: UserProjectRole
  totalTasks?: number
  completedTasks?: number
  unallocatedTasks?: number
  isPaused?: boolean
  startDate?: Date
  completedDate?: Date
  planUploaded: boolean
  isDeleted?: boolean
  deletedLogs?: DeletedLogs[]
}
export interface StepProps {
  stepNumber: number
  currentStep: number
  totalSteps: number
}
export interface StepDefintionProps extends StepProps {
  stepTitle: string
  stepNumber: number
  currentStep: number
  totalSteps: number
  goForward: () => void
  goBack?: () => void
}

export interface Artefact extends RemovableResource {
  key: string
  name: string
  category?: string
  isRM?: boolean
  pagesRedacted?: number
  marks?: number
}

export interface ArtefactCompleted {
  artefact: string
  category: string
  rank: number
  packs: number
  measure: number
  se: number
  medianTimeInSeconds: number
  avgRank?: number
  rankDistribution?: { [key: string]: number }
  mark?: number
}
export interface ArtefactOutcomes {
  artefactRanks: ArtefactCompleted[]
  ssr: number
}
export interface ArtefactUploaderProps {
  filePrefix: string
  existingProjectFiles: Artefact[]
}
export interface FileWithCategory extends File {
  key: string
  category: string
}

export interface KeyFile {
  name: string
  key: string
}

export interface AuthState {
  isAuthenticated: boolean // to check if authenticated or not
  user?: User // store all the user details
  loading?: boolean
}

export interface Credentials extends CredentialsOptions {
  expiration: Date
  iotEndPoint: string
}

export interface CanParams {
  userRoles: string[]
  perform: string
  yes: () => JSX.Element
  no: () => JSX.Element
}

export interface AuthActions {
  refreshUserName: (newName: string) => void
  handleRedirectCallback: () => Promise<void>
  getIdTokenClaims: () => Promise<IdToken>
  loginWithRedirect: (options: RedirectLoginOptions) => Promise<void>
  getTokenSilently: (options?: GetTokenSilentlyOptions) => Promise<string>
  getTokenWithPopup: () => Promise<string>
  logout: () => void
}

export interface AxiosMock extends AxiosStatic {
  [x: string]: any
  mockResolvedValue: Function
  mockRejectedValue: Function
  mockImplementation: Function
}
export type PlanStatus =
  | 'NOPLAN'
  | 'INVALID'
  | 'GOOD'
  | 'RETRIEVEERROR'
  | 'INVALIDINPUT'
  | 'DUEDATEPASSED'
  | 'STARTFAILED'

export type ProjectStatus = 'DRAFT' | 'INPROGRESS' | 'COMPLETE'
export type UserProjectRole = 'OWNER' | 'ADMIN' | 'JUDGE'
export type PlanType = 'MANUAL' | 'AUTO'

export const reg = /^[\s\w!',\-.?]{3,}$/

export interface TaskArtefact {
  artefactKey: string
  initialOrder?: string
  timeOnArtefact?: number
  rankOrder?: number
}
export type TaskStatus = 'INCOMPLETE' | 'COMPLETE'
export interface PlanTask {
  id: string
  pack: number
  judge: string
  artefacts: TaskArtefact[]
  taskTime?: number
  completedDate?: Date
  comments?: string
  status: TaskStatus
}

export interface ArtefactKey {
  name: string
  path: string
}

export interface PlanTaskComments {
  sno: number
  judgeName: string
  artefacts: (string | undefined)[]
  initialOrder: string[]
  rankOrder: string[]
  completedDate: string
  comments: string
}
