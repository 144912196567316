import React, { FC } from 'react'
import { Judge } from '../../../types'
import { Row, Col } from 'reactstrap'
import { nameRenderer, emailRenderer } from '../../../helpers/dom-helpers'
import { TaskProgress } from '../subcomponents/task-progress'
import { Avatar } from '../avatar/avatar'
import { ProfileInitials } from '../profile-initials/ProfileInitials'

export const ReallocateFromRow: FC<Judge & { allocated: number }> = ({
  allocated,
  ...judge
}) => {
  return (
    <div className="rounded border mb-45">
      <Row
        className={`align-items-center rounded  mx-0 px-3 py-35 ${
          judge.isPlaceholder ? 'bg-light' : ''
        }`}
      >
        <Col xs="auto" className="px-1">
          <Avatar
            emailVerified={
              judge.email_verified ||
              (judge.isPlaceholder !== undefined && judge.isPlaceholder)
            }
            pictureSrc={
              judge.isPlaceholder
                ? ProfileInitials(judge?.name)
                : typeof judge.picture != 'undefined' &&
                  judge.picture === '/icon-people-sq.svg'
                ? ProfileInitials(judge.name)
                : judge.picture
            }
            className={
              judge.isPaused || judge.isPlaceholder ? 'grayscale-100' : ''
            }
          />
        </Col>
        <Col
          xs={10}
          className={`text-left ${
            judge.isPlaceholder ? 'text-secondary ' : ''
          }`}
        >
          {nameRenderer(judge.name || 'Not allocated', judge.user_id)}
          {judge.name !== judge.email &&
            judge.email &&
            emailRenderer(judge.email, judge.user_id)}
        </Col>
        <Col>
          {!judge.isPlaceholder && (
            <>
              <div className=" font-weight-bold text-secondary">Progress</div>
              <TaskProgress
                completedTasks={judge.completedTasks}
                totalTasks={judge.totalTasks}
              />
            </>
          )}
        </Col>
        <Col
          style={{ flex: '0 0 100px' }}
          className="text-primary font-weight-bold text-center"
        >
          <div>Remaining</div>
          <div className="h5 mb-0 font-weight-bold">
            {judge.totalTasks - judge.completedTasks - allocated}
          </div>
        </Col>
        <Col style={{ flex: '0 0 50px' }} xs="auto" />
      </Row>
    </div>
  )
}
