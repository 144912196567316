import React, { FC, useMemo, useRef, useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faUsers,
  faCircle,
  faInfoCircle
} from '@fortawesome/pro-solid-svg-icons'
import Avatar from 'react-avatar-edit'
import {
  Button,
  ModalProps,
  UncontrolledTooltip,
  Spinner,
  FormGroup
} from 'reactstrap'
import Dropzone from 'react-dropzone'
import { useAsyncTaskAxios } from 'react-hooks-async'
import axios, { AxiosResponse } from 'axios'
import { CJModal } from '../shared/modal/modal'
import { CJModalHeader } from '../shared/modal/subcomponents/modal-header'
import { CJModalBody } from '../shared/modal/subcomponents/modal-body'
import { CJModalFooter } from '../shared/modal/subcomponents/modal-footer'
import { useConfig } from '../../use-remote-config'
import { useGetBearerToken } from '../use-get-bearer-token'
import { Project, User, reg } from '../../types'
import { useAuth0 } from '../../Auth/auth'

import { ValidatedInput, CheckValid } from '../wizard-steps/validated-input'
import { ProfileInitials } from '../shared/profile-initials/ProfileInitials'

interface ProfileModalProps {
  user: User
}

export const ProfileModal: FC<ModalProps & ProfileModalProps> = ({
  isOpen,
  toggle,
  user
}) => {
  const { refreshUserName, getTokenSilently } = useAuth0()
  const bearerToken = useGetBearerToken()
  const firstNameRef = useRef<CheckValid>(null)
  const lastNameRef = useRef<CheckValid>(null)

  const [draftName, setDraftName] = useState<string>(
    user.email === user.name ? '' : user.name
  )
  const firstName = useMemo(() => {
    const splits = draftName.split(' ')
    return splits[0] === user.email ? '' : splits[0]
  }, [draftName, user.email])

  const lastName = useMemo(() => {
    const splits = draftName.split(' ')
    return splits.length > 1 ? splits[splits.length - 1] : ''
  }, [draftName])

  useEffect(() => {
    setDraftName(user.name)
  }, [user.name])
  const { config } = useConfig()

  const updateProfileMemo = useMemo(() => {
    return {
      url: `${config.apiUrl}/users/${encodeURIComponent(user.user_id)}`,
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${bearerToken}`
      }
    }
  }, [bearerToken, config.apiUrl])

  const updateProfileTask = useAsyncTaskAxios<AxiosResponse<void>>(
    axios,
    updateProfileMemo
  )
  useEffect(() => {
    if (updateProfileTask.result) {
      refreshUserName(`${firstName} ${lastName}`)
      if (toggle) {
        toggle()
      }
    }
  }, [updateProfileTask.result])

  const onSubmit = async () => {
    if (draftName === user.name && user.name !== user.email && toggle) {
      toggle()

      return
    }

    let firstNameValid = false
    let lastNameValid = false

    if (firstNameRef && firstNameRef.current) {
      firstNameValid = firstNameRef.current.checkAndSetValid()
    }
    if (lastNameRef && lastNameRef.current) {
      lastNameValid = lastNameRef.current.checkAndSetValid()
    }

    if (firstNameValid && lastNameValid) {
      updateProfileTask.start({
        data: { firstName, lastName },
        headers: {
          Authorization: `Bearer ${bearerToken}`
        }
      })
    }
  }
  let profileInitialsSvgURI = ProfileInitials(user?.name)
  return (
    <CJModal centered isOpen={isOpen}>
      <CJModalHeader
        style={{ height: '96px' }}
        className="bg-primary w-100 position-relative"
      />
      <CJModalBody>
        <img
          src={
            typeof user.picture != 'undefined' &&
            user.picture === '/icon-people-sq.svg'
              ? ProfileInitials(user?.name)
              : user.picture
          }
          alt="Profile"
          className="profile-img  position-absolute"
        />
        <div className="text-primary font-weight-bold h5 mt-5">
          Edit your profile
        </div>
        <div className="font-weight-bold font-larger">{user.roles[0]}</div>
        <div className="font-weight-bold text-secondary mb-5">{user.email}</div>
        <FormGroup>
          <ValidatedInput
            ref={firstNameRef}
            data-testid="firstname-input"
            type="text"
            maxLength={30}
            name="firstName"
            id="firstName"
            onChange={event => {
              setDraftName(`${event.target.value} ${lastName}`)
            }}
            rows={4}
            className="font-weight-bold"
            onSubmit={() => {
              if (lastNameRef && lastNameRef.current) {
                lastNameRef.current.focus()
              }
            }}
            validationMessage="First name must be at least 2 characters long"
            checkIsValid={newVal => {
              return typeof newVal === 'string' && newVal.trim().length >= 2
            }}
            placeholder="First name"
            value={firstName}
          />
        </FormGroup>
        <FormGroup>
          <ValidatedInput
            ref={lastNameRef}
            data-testid="lastname-input"
            type="text"
            maxLength={30}
            name="lastName"
            id="lastName"
            onChange={event => {
              setDraftName(`${firstName} ${event.target.value}`)
            }}
            rows={4}
            className="font-weight-bold mt-4"
            onSubmit={onSubmit}
            validationMessage="Last name must be at least 2 characters long"
            checkIsValid={newVal => {
              return typeof newVal === 'string' && newVal.trim().length >= 2
            }}
            placeholder="Last name"
            value={lastName}
          />
        </FormGroup>
      </CJModalBody>
      <CJModalFooter>
        <>
          {user.email === user.name && (
            <div className="w-100 text-center font-weight-bold mb-2 text-secondary">
              <FontAwesomeIcon icon={faInfoCircle} className="mr-2" />
              This information is required
            </div>
          )}
          {toggle !== undefined && (
            <>
              {user.name !== undefined &&
                user.name.trim() !== '' &&
                user.name !== user.email && (
                  <Button color="ddd" outline onClick={toggle}>
                    Cancel
                  </Button>
                )}
              <Button
                color="primary"
                data-testid="btn-save-profile"
                disabled={
                  updateProfileTask.started && updateProfileTask.pending
                }
                className="position-relative"
                onClick={onSubmit}
              >
                {updateProfileTask.started && updateProfileTask.pending && (
                  <Spinner size="sm" />
                )}
                {!(updateProfileTask.started && updateProfileTask.pending) &&
                  'Save changes'}
                {draftName !== user.name && (
                  <span
                    aria-label="Changes made"
                    className="fa-layers fa-fw  position-absolute"
                    id="edit-button-span"
                    style={{
                      right: '-.625rem',
                      top: '-0.5rem'
                    }}
                  >
                    <FontAwesomeIcon className="text-danger" icon={faCircle} />

                    <UncontrolledTooltip
                      placement="top"
                      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                      target="edit-button-span"
                    >
                      <div className="px-35 py-3">You've made some changes</div>
                    </UncontrolledTooltip>
                  </span>
                )}
              </Button>
            </>
          )}
        </>
      </CJModalFooter>
    </CJModal>
  )
}
