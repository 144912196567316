import { useCallback, useMemo, useState, useEffect, useReducer } from 'react'
import { useAsyncTaskAxios, useAsyncRun } from 'react-hooks-async'
import axios, { AxiosResponse } from 'axios'
import * as awsIoT from 'aws-iot-device-sdk'
import { take } from 'lodash'
import { useConfig } from '../use-remote-config'
import { useGetS3UploadToken } from './use-get-temp-s3-upload-token'
import { ArtefactView, DraftArtefactView } from '../types'
import { useGetBearerToken } from './use-get-bearer-token'
import aws from 'aws-sdk'

import { getMimeType } from '../helpers/mime-type-helpers'
import { CredentialsOptions } from 'aws-sdk/lib/credentials'

import {
  artefactReducer,
  DraftArtefactState
} from '../reducers/artefact-reducer'
import { getBinary } from '../helpers/get-binary'

export const NOCAT = 'NOCAT'

export interface ArtefactKey {
  name: string
  path: string
}
export interface NewArtefactUploaderState {
  addNewFiles: (acceptedFiles: File[], rejectedFiles: File[]) => Promise<void>
  lastUploadContainedWrongFiles: boolean
  lastUploadContainedNonPdfFiles: boolean
  clearLastUploadError: () => void
  artefactState: DraftArtefactState
  deleteAllInCurrentCategory: () => void
  retrievingArtefacts: boolean
  deletingAllInCategory: boolean
  deleteAllError: Error | null
  getError: Error | null
  deleteStart: (key: string) => void
  deleteComplete: (key: string) => void
  deleteFailed: (key: string) => void
}

const initialState: DraftArtefactState = {
  artefactMap: new Map<string, DraftArtefactView>(),
  categoryMap: new Map<string, Set<string>>(),
  categoryCompleted: new Map<string, Set<string>>(),
  cagtegoryUploading: new Map<string, Set<string>>(),
  queued: new Set<string>(),
  inProgress: new Set<string>()
}

export const useNewArtefactManager = (
  projectId: string,
  currentCategory: string | undefined,
  expected?: string[]
): NewArtefactUploaderState => {
  const { config } = useConfig()

  const bearerToken = useGetBearerToken()
  const getAssetMemo = useMemo(() => {
    return {
      url: `${config.apiUrl}/projects/${projectId}/artefacts`,
      headers: {
        Authorization: `Bearer ${bearerToken}`
      }
    }
  }, [projectId, bearerToken, config.apiUrl])
  const s3tokenTask = useGetS3UploadToken({ autoStart: true })
  const getAssetsTask = useAsyncTaskAxios<AxiosResponse<ArtefactView[]>>(
    axios,
    getAssetMemo
  )
  useAsyncRun(bearerToken && getAssetsTask)

  useEffect(() => {
    if (getAssetsTask.result && getAssetsTask.result.data) {
      dispatch({ type: 'refresh', artefacts: getAssetsTask.result.data })
    }
  }, [getAssetsTask.result])
  const deleteAllUrl = currentCategory
    ? `${config.apiUrl}/projects/${projectId}/artefacts/${currentCategory}`
    : `${config.apiUrl}/projects/${projectId}/artefacts`

  const [state, dispatch] = useReducer(artefactReducer, initialState)

  const s3 = useMemo(() => {
    if (!s3tokenTask.result) {
      return undefined
    }
    const tempS3 = new aws.S3({
      region: 'eu-west-1',
      credentials: s3tokenTask.result.data
    })
    return tempS3
  }, [s3tokenTask.result])

  useEffect(() => {
    const isCancelled = false
    if (state.inProgress.size < 5 && state.queued.size > 0) {
      const toProcessKeys = take(Array.from(state.queued.keys()), 5)
      let toProcess: DraftArtefactView[] = []
      toProcessKeys.forEach(x => {
        const gotX = state.artefactMap.get(x)
        if (gotX) {
          toProcess.push(gotX)
        }
      })
      dispatch({
        type: 'start_upload',
        keys: toProcessKeys
      })

      const getShizzle = async (toUpload: DraftArtefactView[]) => {
        const proms = toUpload.map(
          async (file: DraftArtefactView): Promise<void> => {
            if (file.file) {
              const binaryString = await getBinary(file.file)

              if (binaryString) {
                const params: aws.S3.PutObjectRequest = {
                  Body: binaryString,
                  Bucket: config.bucketName || '',
                  Key: `unprocessed-${file.key}`,
                  ContentType: getMimeType(file.key)
                }
                try {
                  if (s3) {
                    const putter = s3.upload(params)
                    putter.on('httpUploadProgress', (progress): void => {
                      if (!isCancelled) {
                        dispatch({
                          type: 'progress_upload',
                          key: file.key,
                          progress: progress.loaded
                        })
                      }
                    })
                    await putter.promise()
                  }
                } catch (error) {
                  console.log()
                  if (!isCancelled) {
                    dispatch({
                      type: 'upload_error',
                      key: file.key,
                      error
                    })
                  }
                }
                if (!isCancelled) {
                  dispatch({
                    type: 'complete_upload',
                    key: file.key
                  })
                }
              }
            }
          }
        )
        await Promise.all(proms)
      }
      getShizzle(toProcess)
    }
  }, [state.queued, state.inProgress, config.bucketName])

  const [
    lastUploadContainedWrongFiles,
    setLastUploadContainedWrongFiles
  ] = useState(false)

  const [lastUploadContainedNonPdf, setLastUploadContainedNonPdf] = useState(
    false
  )

  useEffect(() => {
    if (s3tokenTask.result) {
      const client = new awsIoT.device({
        clientId: new Date().getTime().toString(),
        protocol: 'wss',
        debug: true,
        accessKeyId: s3tokenTask.result.data.accessKeyId,
        secretKey: s3tokenTask.result.data.secretAccessKey,
        sessionToken: s3tokenTask.result.data.sessionToken,
        host: s3tokenTask.result.data.iotEndPoint,
        region: 'eu-west-1',
        port: 443
      })
      client.on('connect', () => {
        client.subscribe(`artefacts/${projectId}`)
        console.log('Connected')
      })
      client.on('message', (topic, payload) => {
        const a = JSON.parse(payload.toString()) as ArtefactView
        dispatch({ type: 'complete_processing', artefact: a })
      })
      client.on('close', () => {
        console.log('Connection Closed')
      })
      client.on('error', error => {
        console.log(error)
      })

      return () => {
        console.log('unsubscribing')
        client.unsubscribe(`artefactEvents/${projectId}`)

        console.log('end connection')
        client.end()
      }
    }
  }, [s3tokenTask.result, projectId])

  const deleteAllInCategoryMemo = useMemo(() => {
    return {
      url: deleteAllUrl,
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${bearerToken}`
      }
    }
  }, [deleteAllUrl, bearerToken])

  const deleteAllInCategoryTask = useAsyncTaskAxios<AxiosResponse<void>>(
    axios,
    deleteAllInCategoryMemo
  )

  useEffect(() => {
    if (deleteAllInCategoryTask.result) {
      const inThisCat = state.categoryMap.get(currentCategory || NOCAT)
      if (inThisCat) {
        inThisCat?.forEach(x => {
          dispatch({ type: 'remove_complete', key: x })
        })
      }
      // success so remove it from the state
    }
  }, [deleteAllInCategoryTask.result])
  const onDrop = useCallback(
    async (acceptedFiles: File[], rejectedFiles: File[]): Promise<void> => {
      // Do something with the files

      // lets try filtering out here
      const filtered = expected
        ? acceptedFiles.filter(x =>
            expected.map(y => y.toLowerCase()).includes(x.name.toLowerCase())
          )
        : acceptedFiles
      if (rejectedFiles.length > 0) {
        setLastUploadContainedNonPdf(true)
      } else {
        setLastUploadContainedNonPdf(false)
      }
      if (filtered.length < acceptedFiles.length) {
        setLastUploadContainedWrongFiles(true)
      } else {
        setLastUploadContainedWrongFiles(false)
      }
      if (filtered.length > 0) {
        dispatch({
          type: 'add_to_queue',
          projectId: projectId,
          category: currentCategory,
          newFiles: filtered
        })
      }
    },
    [s3tokenTask.result, currentCategory, projectId, expected]
  )
  return {
    addNewFiles: onDrop,
    artefactState: state,
    lastUploadContainedWrongFiles,
    lastUploadContainedNonPdfFiles: lastUploadContainedNonPdf,
    clearLastUploadError: () => {
      setLastUploadContainedWrongFiles(false)
      setLastUploadContainedNonPdf(false)
    },
    retrievingArtefacts: getAssetsTask.pending && getAssetsTask.started,

    deleteAllInCurrentCategory: () => {
      deleteAllInCategoryTask.start()
    },

    deleteAllError: deleteAllInCategoryTask.error,
    getError: getAssetsTask.error,
    deletingAllInCategory:
      deleteAllInCategoryTask.started && deleteAllInCategoryTask.pending,
    deleteStart: (key: string) => {
      dispatch({ type: 'remove_start', key })
    },
    deleteComplete: (key: string) => {
      dispatch({ type: 'remove_complete', key })
    },
    deleteFailed: (key: string) => {
      dispatch({ type: 'remove_error', key })
    }
  }
}
