export const mimeTypes: { [key: string]: string } = {
  '.pdf': 'application/pdf',
  '.mp4': 'video/mp4',
  '.mov': 'video/quicktime',
  '.3gp': 'video/3gpp',
  '.m4v': 'video/x-m4v',
  '.webm': 'video/webm',
  '.mpeg': 'video/mpeg',
  '.dcr': 'application/x-director',
  '.swf': 'application/x-shockwave-flash',
  '.mp3': 'audio/mpeg',
  '.wav': 'audio/wav',
  '.ogg': 'audio/ogg',
  '.aac': 'audio/aac',
  '.m4a': 'audio/mp4'
}

export const getFileExtension = (filename: string): string => {
  return filename.slice(((filename.lastIndexOf('.') - 1) >>> 0) + 1)
}

export const getMimeType = (fileName: string): string => {
  const ext = `${getFileExtension(fileName)}`
  return mimeTypes[ext] || 'application/octet-stream' // Default to binary if unknown
}
