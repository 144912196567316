import React, { FC } from 'react'
import { JudgeAllocation } from '../../../types'
import { Row, Col, Button, Input } from 'reactstrap'
import { nameRenderer, emailRenderer } from '../../../helpers/dom-helpers'
import { TaskProgress } from '../subcomponents/task-progress'
import { faTrash } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Avatar } from '../avatar/avatar'
import { ProfileInitials } from '../profile-initials/ProfileInitials'

export const ReallocateToRow: FC<JudgeAllocation & {
  maxAvailable: number
  onRemoveRowClick: () => void
  onAllocationChange: (newValue: number) => void
  disabled?: boolean
}> = ({
  maxAvailable,
  onRemoveRowClick,
  onAllocationChange,
  disabled,
  ...judge
}) => {
  return (
    <Row className="align-items-center  mx-0 px-3 py-35 adjacent ">
      <Col xs="auto" className="px-1">
        <Avatar
          emailVerified={judge.email_verified}
          pictureSrc={
            typeof judge.picture != 'undefined' &&
            judge.picture === '/icon-people-sq.svg'
              ? ProfileInitials(judge.name)
              : judge.picture
          }
        />
      </Col>
      <Col xs={10} className="text-left">
        {judge.name && nameRenderer(judge.name, judge.user_id)}
        {judge.name !== judge.email &&
          judge.email &&
          emailRenderer(judge.email, judge.user_id)}
      </Col>
      <Col>
        <div className=" font-weight-bold text-secondary">Progress</div>
        <TaskProgress
          completedTasks={judge.completedTasks}
          totalTasks={judge.totalTasks}
        />
      </Col>
      <Col
        style={{ flex: '0 0 100px' }}
        className="text-primary font-weight-bold text-center"
      >
        <Input
          pattern="[0-9][0-9]"
          disabled={disabled}
          style={{ fontSize: '1.25rem' }}
          className="text-center font-weight-bold mx-0 py-2"
          value={judge.toReallocate}
          onChange={newValue => {
            const newVal = parseInt(newValue.target.value, 10)
            onAllocationChange(newVal || 0)
          }}
        />
      </Col>
      <Col
        style={{ flex: '0 0 50px' }}
        className="text-primary font-weight-bold text-center"
      >
        <Button className="p-0" color="link" onClick={onRemoveRowClick}>
          <FontAwesomeIcon size="sm" icon={faTrash} />
        </Button>
      </Col>
    </Row>
  )
}
