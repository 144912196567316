import React, { useMemo } from 'react'

import { faFilePdf } from '@fortawesome/pro-regular-svg-icons'
import { Row, Col, Progress, Button, Spinner } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faExclamationTriangle,
  faCheck,
  faHourglassStart,
  faFlag
} from '@fortawesome/pro-solid-svg-icons'
import { ArtefactView } from '../../../types'
import { useProjectResourceDeleter } from '../../use-project-resource-manager'

/**
 * Artefact Row Props
 */
export interface ArtefactRowProps extends React.HTMLAttributes<HTMLDivElement> {
  /** id of the project */
  projectId: string
  /** current state of the artefact */
  artefact: ArtefactView
  showRM: boolean // this is a weird one
  resourceId: string // odd
  showItem: (key: string) => void
  onDeleteStart: () => void
  onDeleteComplete: () => void
  onDeleteError: () => void
}

/**
 * Artefact Row is wonderful
 */
export const DraftArtefactRow: React.FC<ArtefactRowProps> = ({
  projectId,
  artefact,
  showRM,
  style,
  showItem,
  resourceId,
  onDeleteComplete,
  onDeleteError,
  onDeleteStart,
  className
}: ArtefactRowProps) => {
  const { deleteResource } = useProjectResourceDeleter(
    projectId,
    'artefacts',
    resourceId,
    onDeleteStart,
    onDeleteComplete,
    onDeleteError
  )
  const isUploading = useMemo(() => {
    return (
      artefact.uploadingState &&
      (artefact.uploadingState.isLoading ||
        (artefact.uploadingState.isComplete &&
          !artefact.uploadingState.isProcessed))
    )
  }, [artefact])
  const isError = useMemo(() => {
    return (
      artefact.deleteFailed ||
      (artefact.uploadingState && artefact.uploadingState.isError)
    )
  }, [artefact])
  const isProcessing = useMemo(() => {
    return (
      artefact.deleting ||
      (artefact.uploadingState && artefact.uploadingState.isLoading) ||
      (artefact.uploadingState &&
        artefact.uploadingState.isComplete &&
        !artefact.uploadingState.isProcessed &&
        !artefact.uploadingState.isError)
    )
  }, [artefact])
  const isQueued = useMemo(() => {
    return (
      artefact.uploadingState &&
      !artefact.uploadingState.isLoading &&
      !(artefact.uploadingState.isComplete || artefact.uploadingState.isError)
    )
  }, [artefact.uploadingState])
  const infoText = useMemo(() => {
    if (isQueued) return 'Queued...'
    if (artefact.deleting) return 'Deleting this artefact...'
    if (artefact.deleteFailed) return 'Deleting this artefact has failed'
    if (artefact.uploadingState && artefact.uploadingState.isError)
      return 'Uploading this artefact has failed'
    if (
      artefact.uploadingState &&
      artefact.uploadingState.isComplete &&
      !artefact.uploadingState.isProcessed
    ) {
      return 'Analyzing...'
    }
  }, [artefact, isQueued])
  return (
    <Row
      style={style}
      key={artefact.name}
      className={`${className || ''} ${
        artefact.deleting || artefact.deleteFailed || isQueued
          ? 'bg-light text-secondary'
          : ''
      } align-items-center align-content-center mx-0`}
    >
      <Col className="font-weight-bold py-35 pl-35">
        {isQueued && (
          <FontAwesomeIcon
            fixedWidth
            className="mr-3"
            icon={faHourglassStart}
          />
        )}
        {isProcessing && (
          <Spinner
            className={`${isUploading ? 'text-primary' : ''} mr-3 ml-1`}
            size="sm"
          />
        )}
        {isError && (
          <FontAwesomeIcon
            fixedWidth
            className="text-danger mr-3"
            icon={faExclamationTriangle}
          />
        )}
        {!isQueued && !isProcessing && !isError && (
          <>
            {artefact.isRM && (
              <FontAwesomeIcon
                fixedWidth
                data-testid="rm-artefact"
                className="text-primary mr-3"
                icon={faCheck}
              />
            )}
            {!artefact.isRM && showRM && (
              <FontAwesomeIcon
                data-testid="rm-artefact"
                fixedWidth
                className="text-danger mr-3"
                icon={faFlag}
              />
            )}
            {!artefact.isRM && !showRM && (
              <FontAwesomeIcon
                fixedWidth
                className="text-dark mr-3"
                icon={faFilePdf}
              />
            )}
          </>
        )}

        {(artefact.uploadingState === undefined ||
          artefact.uploadingState.isProcessed) && (
          <a
            className={`${artefact.deleting ? 'text-secondary' : 'text-dark'}`}
            href={`/artefacts/${artefact.key}`}
            onClick={event => {
              event.nativeEvent.preventDefault()

              showItem(artefact.key)
            }}
          >
            {artefact.name}
          </a>
        )}
        {artefact.uploadingState && !artefact.uploadingState.isProcessed && (
          <span
            className={`${
              artefact.uploadingState.isError ? 'text-danger' : ''
            }`}
          >
            {artefact.name}
          </span>
        )}
      </Col>
      <Col
        style={{ minWidth: '64px' }}
        xs="auto"
        className="align-items-center text-right pr-35"
      >
        <span
          className={`${
            isError ? 'text-danger' : 'text-secondary'
          } font-weight-bold`}
        >
          {infoText}
        </span>
        {(artefact.deleteFailed ||
          !artefact.uploadingState ||
          (artefact.uploadingState && artefact.uploadingState.isProcessed)) && (
          <Button
            disabled={artefact.deleting}
            data-testid="removeArtefact"
            close
            className="ml-3 float-none"
            onClick={() => {
              deleteResource()
            }}
          />
        )}
      </Col>
      <div className="px-0 mt-n1 w-100">
        {artefact.uploadingState &&
          !artefact.uploadingState.isProcessed &&
          !artefact.uploadingState.isError &&
          !isQueued && (
            <Progress
              className="rounded-0 progress-slim-1 "
              value={artefact.uploadingState.loaded}
              max={artefact.uploadingState.total}
              min={0}
              aria-valuenow={artefact.uploadingState.loaded}
              aria-valuemin={0}
              aria-valuemax={artefact.uploadingState.total}
            />
          )}
      </div>
    </Row>
  )
}
