import React from 'react'

import { Button, ModalProps } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faExclamationTriangle,
  faMessage
} from '@fortawesome/pro-solid-svg-icons'
import { CJModal } from '../shared/modal/modal'
import { CJModalHeader } from '../shared/modal/subcomponents/modal-header'
import { CJModalBody } from '../shared/modal/subcomponents/modal-body'
import {
  SimpleModalMessage,
  ModalErrorProps
} from '../shared/modal/subcomponents/modal-message'
import { CJModalFooter } from '../shared/modal/subcomponents/modal-footer'

export const CJShowCommentsModal: React.FunctionComponent<ModalProps &
  ModalErrorProps> = ({
  title,
  isOpen,
  onDismiss,
  message = 'Comments',
  ...rest
}) => {
  return (
    <CJModal
      role="dialog"
      centered
      isOpen={isOpen}
      toggle={() => onDismiss()}
      {...rest}
    >
      <CJModalHeader className={'bg-primary'}>
        <FontAwesomeIcon
          icon={faMessage}
          color="white"
          className="my-2"
          size="lg"
        />
      </CJModalHeader>
      <CJModalBody>
        <SimpleModalMessage title={title} message={message} />
      </CJModalBody>
      <CJModalFooter>
        <Button
          outline
          onClick={() => {
            onDismiss()
          }}
        >
          Go back
        </Button>
      </CJModalFooter>
    </CJModal>
  )
}
export const MemoizedCJShowCommentsModal = React.memo(CJShowCommentsModal)
