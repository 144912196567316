import React, { useState, useEffect } from 'react'
import {
  Collapse,
  Container,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  Row,
  Col,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faPowerOff,
  faGavel,
  faUserEdit
} from '@fortawesome/pro-solid-svg-icons'
import { useAuth0 } from '../Auth/auth'
import caLogo from '../assets/calogo.svg'
import { ProfileModal } from './modals/profile-modal'
import { ProfileInitials } from './shared/profile-initials/ProfileInitials'

export const NavBar = (): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false)
  const { user, logout, isAuthenticated } = useAuth0()
  const [showProfileModal, setShowProfileModal] = useState(
    user !== undefined && (user.name === undefined || user.name === user.email)
  )
  const toggle = (): void => {
    setIsOpen(!isOpen)
  }
  useEffect(() => {
    setShowProfileModal(
      user !== undefined &&
        (user.name === undefined || user.name === user.email)
    )
  }, [user])
  return (
    <div className="nav-container">
      <Navbar color="faded" className="border-bottom py-3" light expand="md">
        <Container>
          <Row className="w-100 align-items-center no-gutters">
            <Col>
              <NavbarBrand
                tag={Link}
                className="align-items-center  font-weight-bold p-0"
                to="/"
              >
                <img
                  src={caLogo}
                  className="d-inline-block align-top mr-4"
                  alt="Cambridge University Press Assessment Logo"
                />
              </NavbarBrand>
            </Col>

            <Col
              xs={18}
              sm={12}
              md={8}
              className="font-weight-bold mt-2 mt-sm-0 text-black text-left text-sm-center"
            >
              Comparative Judgement
            </Col>
            <Col className="text-right">
              <NavbarToggler onClick={toggle} />
              <Collapse isOpen={isOpen} navbar>
                <Nav navbar />
                <Nav className="ml-auto align-items-center" navbar>
                  {isAuthenticated && user && (
                    <>
                      <div>
                        <div className="font-weight-bold font-smaller">
                          {user.roles[0]}
                        </div>
                        <div className="text-secondary font-smaller">
                          {user.name}
                        </div>
                      </div>

                      <UncontrolledDropdown nav inNavbar>
                        <DropdownToggle
                          nav
                          className="ml-3 p-0"
                          data-testid="header-toggle"
                        >
                          <img
                            src={
                              typeof user.picture != 'undefined' &&
                              user.picture === '/icon-people-sq.svg'
                                ? ProfileInitials(user?.name)
                                : user.picture
                            }
                            alt="Profile"
                            className="nav-user-profile"
                          />
                        </DropdownToggle>
                        <DropdownMenu right className="mt-3">
                          <DropdownItem
                            className="text-left py-3 font-weight-bold mr-4 "
                            onClick={() => setShowProfileModal(true)}
                          >
                            <FontAwesomeIcon
                              icon={faUserEdit}
                              className="mr-3"
                            />
                            Edit profile
                          </DropdownItem>
                          {user.roles.length > 1 && (
                            <DropdownItem
                              tag={Link}
                              id="qsLogoutBtn"
                              to="/tasks"
                              className="text-left py-3  font-weight-bold mr-4 "
                            >
                              <FontAwesomeIcon
                                icon={faGavel}
                                className="mr-3"
                              />
                              My tasks
                            </DropdownItem>
                          )}

                          <DropdownItem
                            id="qsLogoutBtn"
                            className="text-left text-redonblack py-3 font-weight-bold mr-4 "
                            onClick={() => logout()}
                          >
                            <FontAwesomeIcon
                              icon={faPowerOff}
                              className="mr-3"
                            />
                            Log out
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </>
                  )}
                </Nav>
              </Collapse>
            </Col>
          </Row>
        </Container>
      </Navbar>
      {user && (
        <ProfileModal
          user={user}
          isOpen={showProfileModal}
          toggle={() => setShowProfileModal(!showProfileModal)}
        />
      )}
    </div>
  )
}
