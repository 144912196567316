import React, { FC, useMemo, useState } from 'react'
import { Table, Progress, UncontrolledTooltip, Row, Col } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { values } from 'lodash'
import { useTable, useSortBy, CellProps, useBlockLayout } from 'react-table'
import { faCaretDown, faCaretUp } from '@fortawesome/pro-solid-svg-icons'
import { faFilePdf } from '@fortawesome/pro-regular-svg-icons'
import { ArtefactCompleted } from '../../types'
import { secondsToString } from './completed-judge-view'
import { ArtefactViewer } from '../artefact-viewer'
import { SimpleResponsiveBarChart } from '../simple-responsive-bar-chart'
import { buildKeyFromParts } from '../../helpers/artefact-key-helpers'
import { sanitizeId } from '../../helpers/dom-helpers'

interface ArtefactCompletedProps {
  projectId: string
  artefacts: ArtefactCompleted[]
  artefactsPerPack: number
}
const getOrdinal = (n: number) =>
  ['st', 'nd', 'rd'][(((((n < 0 ? -n : n) + 90) % 100) - 10) % 10) - 1] || 'th'

const getWinRate = (x: ArtefactCompleted): string => {
  return x.rankDistribution !== undefined &&
    x.rankDistribution['1'] !== undefined &&
    x.rankDistribution['2'] !== undefined
    ? (
        (x.rankDistribution['1'] /
          (x.rankDistribution['1'] + x.rankDistribution['2'])) *
        100
      ).toFixed(0)
    : '0'
}

export const CompletedArtefactView: FC<ArtefactCompletedProps> = ({
  artefacts,
  projectId,
  artefactsPerPack
}): JSX.Element => {
  const [selectedArtefactKey, setSelectedArtefactKey] = useState<
    ArtefactCompleted | undefined
  >(undefined)
  const [showViewer, setShowViewer] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [popupText, setPopupText] = useState('')
  const maxBadBoy = useMemo(() => {
    const flat = artefacts.map(row => {
      return Math.max(...values(row.rankDistribution || []))
    })
    return Math.max(...flat)
  }, [artefacts])

  const showItem = (key: ArtefactCompleted) => {
    setSelectedArtefactKey(key)
    setShowViewer(true)
  }
  const toggle = () => {
    setShowViewer(!showViewer)
  }
  const columns = useMemo(() => {
    const firstCols = [
      {
        Header: '#',
        accessor: 'rank',

        headerProps: {
          className: 'text-center text-uppercase'
        },
        cellProps: {
          className: 'text-center font-larger font-weight-bold'
        }
      },
      {
        Header: 'Artefact',
        accessor: 'key',
        width: 415,
        headerProps: {
          className: ' text-uppercase w-422'
        },
        cellProps: {
          className: 'w-422'
        },
        Cell: ({ cell }: CellProps<ArtefactCompleted>) => {
          const { category, artefact } = cell.row.original

          return (
            <a
              className="text-body"
              href={`/artefacts/${buildKeyFromParts(
                projectId,
                artefact,
                category
              )}`}
              onClick={event => {
                event.nativeEvent.preventDefault()

                showItem(cell.row.original)
              }}
            >
              <span
                style={{ whiteSpace: 'nowrap' }}
                className="d-inline-block text-truncate"
              >
                <FontAwesomeIcon size="lg" icon={faFilePdf} className="mr-3" />
                {category && <b>{`${category} / `}</b>}
                <span className="text-truncate" title={artefact}>
                  {artefact}
                </span>
              </span>
            </a>
          )
        }
      },
      {
        Header: 'Packs',
        accessor: 'packs',

        headerProps: {
          className: 'text-center text-uppercase'
        },
        cellProps: {
          className: 'text-center h5 mb-0 font-weight-bold'
        }
      }
    ]
    const middleCols =
      artefactsPerPack === 2
        ? [
            {
              Header: 'Win Rate',
              id: 'winRate',
              accessor: getWinRate,

              Cell: ({ cell }: CellProps<ArtefactCompleted>) => {
                return <>{`${cell.value}%`}</>
              },
              headerProps: {
                className: 'text-center text-uppercase'
              },
              cellProps: {
                className: 'text-center h5 mb-0 font-weight-bold'
              }
            },
            {
              Header: 'Win',
              id: 'win',
              accessor: getWinRate,

              Cell: ({ cell }: CellProps<ArtefactCompleted>) => {
                const cellIdInitial = sanitizeId(
                  `${cell.row.original.category}-${cell.row.original.artefact}`
                )
                const cellId = cellIdInitial.replace(/[\(\)&\]\[']+/g, '')
                // +               console.log('cell Id::::', cellId)

                return (
                  <>
                    <Progress
                      id={`pb-${cellId}`}
                      className="mb-0 progress-slim-3"
                      value={cell.value}
                    />
                    {cell.row.original.rankDistribution && (
                      <UncontrolledTooltip
                        placement="top"
                        target={`pb-${cellId}`}
                      >
                        <div className="p-2 font-larger font-weight-bold">
                          {cell.row.original.rankDistribution['1']}
                          {' / '}
                          {cell.row.original.rankDistribution['1'] +
                            cell.row.original.rankDistribution['2']}
                        </div>
                      </UncontrolledTooltip>
                    )}
                  </>
                )
              },
              headerProps: {
                className: 'text-center text-uppercase'
              },
              cellProps: {
                className: 'text-center h5 mb-0 font-weight-bold'
              }
            }
          ]
        : [
            {
              Header: 'AVG Rank',
              id: 'avgRank',

              accessor: (x: ArtefactCompleted) => {
                return x.avgRank ? x.avgRank.toFixed(2) : undefined
              },
              headerProps: {
                className: 'text-center text-uppercase'
              },
              cellProps: {
                className: 'text-center h5 mb-0 font-weight-bold'
              }
            },
            {
              Header: 'Rank Distribution',
              maxWidth: 191,

              id: 'rankDistribution',
              accessor: (x: ArtefactCompleted) => {
                return x.avgRank
              },
              Cell: ({ cell }: CellProps<ArtefactCompleted>) => {
                const cellId = sanitizeId(
                  `${cell.row.original.category}-${cell.row.original.artefact}`
                )
                return (
                  <>
                    <div
                      id={`pb-${cellId}`}
                      className="position-relative d-flex flex-column"
                      style={{
                        margin: '0 auto',
                        maxWidth: `191px`,
                        height: '32px',
                        maxHeight: '32px',
                        minHeight: '32px',
                        minWidth: `${Object.keys(
                          cell.row.original.rankDistribution || {}
                        ).length *
                          1.5 *
                          8 +
                          16}px`
                      }}
                    >
                      <SimpleResponsiveBarChart
                        maxDataValue={maxBadBoy}
                        data={cell.row.original.rankDistribution || {}}
                      />
                    </div>
                    <UncontrolledTooltip
                      placement="top"
                      target={`pb-${cellId}`}
                    >
                      {cell.row.original.rankDistribution && (
                        <div className="p-2 py-3" key={cellId}>
                          {Object.keys(cell.row.original.rankDistribution).map(
                            (x, i) => (
                              <React.Fragment key={`${cellId}-${x}`}>
                                {cell.row.original.rankDistribution &&
                                  cell.row.original.rankDistribution[x] !==
                                    undefined && (
                                    <Row
                                      className={`${
                                        i === 0 ? '' : 'mt-2'
                                      } align-items-center font-weight-bold`}
                                    >
                                      <Col className="text-left">
                                        {x}
                                        <sup>{getOrdinal(parseInt(x, 10))}</sup>
                                      </Col>
                                      <Col className="text-right h5 mb-0 font-weight-bold">
                                        {cell.row.original.rankDistribution[x]}
                                      </Col>
                                    </Row>
                                  )}
                              </React.Fragment>
                            )
                          )}
                        </div>
                      )}
                    </UncontrolledTooltip>
                  </>
                )
              },
              headerProps: {
                className: 'text-center text-uppercase'
              },
              cellProps: {
                className: 'text-center h5 mb-0 font-weight-bold'
              }
            }
          ]
    let endCols = [
      {
        Header: 'Measure',

        accessor: (x: ArtefactCompleted) =>
          Math.round(x.measure * 10000) / 10000,
        Cell: ({ cell }: CellProps<ArtefactCompleted>) =>
          String(cell.value.toFixed(2)),
        headerProps: {
          className: 'text-center text-uppercase'
        },
        cellProps: {
          className: 'text-center h5 mb-0 font-weight-bold'
        }
      },
      {
        Header: 'SE',

        accessor: (x: ArtefactCompleted) => Math.round(x.se * 10000) / 10000,
        Cell: ({ cell }: CellProps<ArtefactCompleted>) =>
          String(cell.value.toFixed(2)),
        headerProps: {
          className: 'text-center'
        },
        cellProps: {
          className: 'text-center h5 mb-0 font-weight-bold'
        }
      },
      {
        Header: 'AVG Time',

        accessor: 'medianTimeInSeconds',
        Cell: ({ cell }: CellProps<ArtefactCompleted>) =>
          secondsToString(cell.value),
        headerProps: {
          className: 'text-center text-uppercase'
        },
        cellProps: {
          className: 'text-center font-larger'
        }
      }
    ]
    if (artefacts.filter(x => x.mark !== undefined).length > 0) {
      endCols = [
        ...endCols,
        {
          Header: 'Mark',

          accessor: 'mark',
          Cell: ({ cell }: CellProps<ArtefactCompleted>) =>
            cell.value ? String(cell.value) : '-',
          headerProps: {
            className: 'text-center text-uppercase'
          },
          cellProps: {
            className: 'text-center h5 mb-0 font-weight-bold'
          }
        }
      ]
    }
    return [...firstCols, ...middleCols, ...endCols]
  }, [artefactsPerPack, artefacts, projectId, maxBadBoy])
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,

    rows,
    prepareRow
  } = useTable<ArtefactCompleted>(
    {
      columns,
      data: artefacts
    },
    useSortBy
  )

  return (
    <>
      <Table striped className="rounded mb-6" {...getTableProps()}>
        <thead className="text-primary bg-light font-weight-bold">
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                // Add the sorting props to control sorting. For this example
                // we can add them into the header props
                <th
                  {...column.getHeaderProps(
                    (column as any).getSortByToggleProps(
                      (column as any).headerProps
                    )
                  )}
                >
                  {column.render('Header')}
                  {/* Add a sort direction indicator */}
                  <span>
                    {(column as any).isSorted ? (
                      (column as any).isSortedDesc ? (
                        <FontAwesomeIcon
                          className="ml-2 position-absolute"
                          icon={faCaretDown}
                        />
                      ) : (
                        <FontAwesomeIcon
                          className="ml-2 position-absolute"
                          icon={faCaretUp}
                        />
                      )
                    ) : (
                      ''
                    )}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map(row => {
            prepareRow(row)
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => {
                  return (
                    <td {...cell.getCellProps((cell.column as any).cellProps)}>
                      {cell.render('Cell')}
                    </td>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </Table>
      {selectedArtefactKey && (
        <ArtefactViewer
          showAllPages
          artefactName={selectedArtefactKey.artefact}
          artefactKey={buildKeyFromParts(
            projectId,
            selectedArtefactKey.artefact,
            selectedArtefactKey.category
          )}
          isOpen={showViewer}
          toggle={toggle}
        />
      )}
    </>
  )
}
