import React, { useMemo } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCheckCircle,
  faCloudUploadAlt,
  faFilePdf,
  faInfoCircle
} from '@fortawesome/pro-solid-svg-icons'
import { Row, Col, Button, UncontrolledTooltip } from 'reactstrap'
import { useDropzone } from 'react-dropzone'
import { keys, findIndex } from 'lodash'
import { Project, PlanSummary, DraftArtefactView } from '../../types'
import { NOCAT, NewArtefactUploaderState } from '../use-artefact-manager-new'
import { InlineErrorMessage } from '../shared/simple-message/inline-error-message'
import { SimpleMessage } from '../shared/simple-message/simple-message'
import { FaDragArtefact } from '../shared/icons/fa-drag-artefact'
import { DeleteCategoryButton } from './delete-category-button'
import { ArtefactLoader } from '../artefact-loader'
import { TabList } from '../shared/tabs/tab-list'
import { ArtefactCategoryTab } from './artefact-category-tab'

interface DraftAfrefactViewProps {
  project: Project
  currentCategory?: string
  manager: NewArtefactUploaderState
  plan: PlanSummary | undefined
}

export const DraftArtefactsView: React.FC<DraftAfrefactViewProps> = ({
  project,
  manager,
  plan,
  currentCategory
}): JSX.Element => {
  const {
    retrievingArtefacts,
    artefactState,
    addNewFiles,
    lastUploadContainedWrongFiles,
    lastUploadContainedNonPdfFiles,
    clearLastUploadError,

    deleteAllInCurrentCategory,
    deleteStart,
    deleteFailed,
    deleteComplete,
    deleteAllError,

    deletingAllInCategory
  } = manager

  const accept = [
    '.pdf', // PDF
    '.mp4',
    '.mov',
    '.3gp',
    '.m4v',
    '.webm',
    '.mpeg',
    '.dcr',
    '.swf', // Video
    '.mp3',
    '.wav',
    '.ogg',
    '.aac',
    '.m4a' // Audio
  ]

  const dropZoneState = useDropzone({
    onDrop: addNewFiles,
    accept: accept.join(', '),
    noClick: true,
    noKeyboard: true
  })

  const artefactViewsForCategory = useMemo(() => {
    const catKeys = artefactState.categoryMap.get(currentCategory || NOCAT)
    const artefacts: DraftArtefactView[] = []
    if (catKeys) {
      catKeys.forEach(x => {
        const thisOne = artefactState.artefactMap.get(x)
        if (thisOne) {
          artefacts.push(thisOne)
        }
      })
    }
    return artefacts
  }, [artefactState, currentCategory])

  const categoriesRM = useMemo(() => {
    let catMap = new Map<string, boolean>()
    artefactState.categoryCompleted.forEach((val, catKey) => {
      const arr = Array.from(val)
      const i = findIndex(arr, (key: string) => {
        const existing = artefactState.artefactMap.get(key)
        return (
          existing !== undefined &&
          existing.isRM !== undefined &&
          existing.isRM === true
        )
      })

      catMap.set(catKey, i > -1)
    })

    return catMap
  }, [artefactState.artefactMap, artefactState.categoryCompleted])

  const categoriesNonRM = useMemo(() => {
    let catMap = new Map<string, boolean>()
    artefactState.categoryCompleted.forEach((val, catKey) => {
      const arr = Array.from(val)
      const i = findIndex(arr, (key: string) => {
        const existing = artefactState.artefactMap.get(key)
        return (
          existing !== undefined &&
          existing.isRM !== undefined &&
          existing.isRM === false
        )
      })

      catMap.set(catKey, i > -1)
    })

    return catMap
  }, [artefactState.artefactMap, artefactState.categoryCompleted])

  const currentCategoryExpectedIsMetArtefacts = useMemo(() => {
    if (
      !project.planUploaded ||
      !plan ||
      !artefactState.categoryMap.has(currentCategory || NOCAT)
    ) {
      return undefined
    }

    const soFar = artefactViewsForCategory.length
    return (
      plan.artefactCategoryGroups[currentCategory || NOCAT].length === soFar
    )
  }, [plan, project.planUploaded, artefactViewsForCategory, currentCategory])

  return (
    <>
      {project.categories.length > 0 && (
        <Row className="mb-45">
          <Col>
            <TabList>
              {project.categories.map(x => {
                const artefactCount = artefactState.categoryCompleted.has(
                  x || NOCAT
                )
                  ? artefactState.categoryCompleted.get(x || NOCAT)!.size
                  : 0
                const uploadCount = artefactState.cagtegoryUploading.has(
                  x || NOCAT
                )
                  ? artefactState.cagtegoryUploading.get(x || NOCAT)!.size
                  : 0
                const hasRM = categoriesRM.has(x || NOCAT)
                  ? categoriesRM.get(x || NOCAT)!
                  : false
                return (
                  <ArtefactCategoryTab
                    key={x}
                    categoryName={x}
                    isActive={currentCategory === x}
                    artefactCount={artefactCount}
                    hasRM={hasRM}
                    pending={retrievingArtefacts}
                    uploadingCount={uploadCount}
                    artefactLink={`/projects/${project.id}/artefacts`}
                    expectedArtefactCount={
                      project.planUploaded &&
                      plan &&
                      plan.artefactCategoryGroups[x]
                        ? plan.artefactCategoryGroups[x].length
                        : undefined
                    }
                  />
                )
              })}
            </TabList>
          </Col>
        </Row>
      )}
      <Row className="mb-5">
        <Col>
          {!retrievingArtefacts && project && (
            <>
              {lastUploadContainedWrongFiles && (
                <InlineErrorMessage
                  className="mb-45 mx-0 bg-white "
                  title="You've tried to upload artefacts that are not in the plan"
                  message=""
                  close={() => {
                    clearLastUploadError()
                  }}
                />
              )}
              {lastUploadContainedNonPdfFiles && (
                <InlineErrorMessage
                  className="mb-45 mx-0 bg-white "
                  title="You've tried to upload artefacts that are not PDF"
                  message=""
                  close={() => {
                    clearLastUploadError()
                  }}
                />
              )}
              <Row>
                <Col>
                  <div
                    className={`${
                      artefactViewsForCategory.length > 0 ||
                      (project.planUploaded &&
                        plan &&
                        plan.artefactCategoryGroups[currentCategory || NOCAT] &&
                        plan.artefactCategoryGroups[currentCategory || NOCAT]
                          .length > 0)
                        ? 'border'
                        : ''
                    } overflow-auto  rounded  p-0 ${
                      dropZoneState.isDragActive ? 'input-focus' : ''
                    }
                    ${artefactViewsForCategory.length === 0 ? 'bg-light' : ''}
                    `}
                    {...dropZoneState.getRootProps({})}
                    data-testid="dropzone"
                  >
                    <input {...dropZoneState.getInputProps()} />
                    {!project.planUploaded &&
                      artefactViewsForCategory.length === 0 && (
                        <SimpleMessage
                          title="You have no artefacts here yet"
                          message="Please drag and drop the artefacts or click button to select"
                          icon={<FaDragArtefact />}
                          className="m-4"
                        >
                          <Button
                            id="btn-upload-msg"
                            className="mt-4"
                            color="primary"
                            onClick={() => dropZoneState.open()}
                          >
                            <FontAwesomeIcon
                              icon={faCloudUploadAlt}
                              className="mr-2"
                            />
                            Upload Artefacts
                          </Button>
                          <UncontrolledTooltip
                            target="btn-upload-msg"
                            placement="top"
                          >
                            <div className="p-2">
                              <FontAwesomeIcon
                                icon={faFilePdf}
                                className="mr-2"
                              />
                              File format must be a .PDF
                            </div>
                          </UncontrolledTooltip>
                        </SimpleMessage>
                      )}
                    {(project.planUploaded ||
                      artefactViewsForCategory.length > 0) && (
                      <>
                        <Row className="py-4 px-35 bg-light rounded-top border-bottom align-items-center mx-0">
                          <Col className="d-flex flex-row flex-wrap align-items-center pl-0">
                            {currentCategoryExpectedIsMetArtefacts && (
                              <FontAwesomeIcon
                                size="lg"
                                className="text-primary mr-2 "
                                icon={faCheckCircle}
                              />
                            )}
                            {!currentCategoryExpectedIsMetArtefacts && (
                              <FontAwesomeIcon
                                className="mr-3"
                                icon={faInfoCircle}
                              />
                            )}
                            <div
                              className=" flex-grow-1 flex-shrink-1"
                              style={{ flexBasis: 0 }}
                            >
                              {!currentCategoryExpectedIsMetArtefacts && (
                                <span>
                                  {'Please '}
                                  <b>drag and drop the artefacts </b>
                                  {' or click button to select'}
                                </span>
                              )}
                              {currentCategoryExpectedIsMetArtefacts && (
                                <b>
                                  All artefacts are uploaded according to your
                                  plan
                                </b>
                              )}
                            </div>
                          </Col>
                          <Col
                            xs={24}
                            md="auto"
                            className="text-right mt-3 mt-md-0 pr-0"
                          >
                            <Button
                              id="btn-upload-row"
                              color="primary"
                              disabled={currentCategoryExpectedIsMetArtefacts}
                              onClick={() => dropZoneState.open()}
                            >
                              <FontAwesomeIcon
                                icon={faCloudUploadAlt}
                                className="mr-2"
                              />
                              Upload artefacts
                            </Button>
                            {artefactViewsForCategory.length > 0 && (
                              <DeleteCategoryButton
                                isDeleting={deletingAllInCategory}
                                deleteError={deleteAllError}
                                onDelete={() => {
                                  deleteAllInCurrentCategory()
                                }}
                              />
                            )}
                            <UncontrolledTooltip
                              target="btn-upload-row"
                              placement="top"
                            >
                              <div className="p-2">
                                <FontAwesomeIcon
                                  icon={faFilePdf}
                                  className="mr-2"
                                />
                                File format must be a .PDF
                              </div>
                            </UncontrolledTooltip>
                          </Col>
                        </Row>

                        <ArtefactLoader
                          projectId={project.id}
                          showRM={
                            categoriesRM.has(currentCategory || NOCAT)
                              ? categoriesRM.get(currentCategory || NOCAT)!
                              : false
                          }
                          onDeleteStart={deleteStart}
                          onDeleteComplete={deleteComplete}
                          onDeleteError={deleteFailed}
                          expected={
                            project.planUploaded && plan
                              ? plan.artefactCategoryGroups[
                                  currentCategory || NOCAT
                                ]
                              : []
                          }
                          data={artefactViewsForCategory}
                        />
                      </>
                    )}
                  </div>
                </Col>
              </Row>
            </>
          )}
        </Col>
      </Row>
    </>
  )
}
